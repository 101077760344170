import { Div, Skeleton } from '@dnb/eufemia';
import type { SectionSpacing } from '@dnb/eufemia/components/Section';
import {
  type JSX,
  type PropsWithChildren,
  type ReactNode,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';

import useReturnTo from '@/hooks/useReturnTo';

import Container from '../Container';
import Footer from '../Footer';
import NavigationBar from '../NavigationBar';
import HeroHeader from './HeroHeader';

import style from './index.module.css';

type PageProps = PropsWithChildren<{
  title: string;
  heroIllustration: string;
  subtitle?: string;
  heroChildren?: ReactNode;
  component?: ReactNode;
  skeleton?: boolean;
  noContainer?: boolean;
  noTopMargin?: boolean;
  styleType?: string;
  spacing?: SectionSpacing;
  heroAltText?: string;
  noContainerStyle?: boolean;
  heroLeftComponent?: ReactNode;
}>;

export default function HeroPage({
  title,
  subtitle,
  heroIllustration,
  heroChildren,
  children,
  skeleton = false,
  noContainer = false,
  noTopMargin = false,
  noContainerStyle = false,
  heroAltText = `Header illustration of the ${title} page`,
  heroLeftComponent,
}: PageProps): JSX.Element {
  const location = useLocation();
  const setReturnTo = useReturnTo()[1];

  useEffect(() => {
    setReturnTo(location.pathname);
  }, [location.pathname, setReturnTo]);

  return (
    <div className={style['pageContainer']}>
      <NavigationBar />
      <header>
        <HeroHeader
          altText={heroAltText}
          heroLeftComponent={heroLeftComponent}
          heroSubtitle={subtitle}
          heroText={title}
          image={heroIllustration}
        >
          {heroChildren && heroChildren}
        </HeroHeader>
      </header>
      <Div
        className={noContainerStyle ? undefined : style['Content']}
        space={{ top: noTopMargin ? 0 : 4 }}
      >
        <Skeleton show={skeleton}>
          {noContainer ? children : <Container centered>{children}</Container>}
        </Skeleton>
      </Div>
      <Footer />
    </div>
  );
}
