import { Button, FormRow, FormSet, Input } from '@dnb/eufemia';
import { send_medium as SendMediumIcon } from '@dnb/eufemia/icons';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { isInternalUser } from '@portals/shared-frontend/utils';
import { type JSX, useCallback, useState } from 'react';
import { z } from 'zod';

import { forgotPassword } from '@/auth/cognito';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import ModalPage from '@/components/ModalPage';

import VerifyNewPassword from './VerifyNewPassword';

const forgotPasswordFormSchema = z
  .object({
    email: z.string().email(),
  })
  .refine((data) => !isInternalUser(data.email), {
    message:
      'DNB users use single sign on login. If you want to reset your password, you have to change your password on your DNB account.',
    path: ['email'],
  });

export default function ForgotPassword(): JSX.Element {
  const [verifyNewPassword, setVerifyNewPassword] = useState<boolean>(false);

  const {
    controller: { values, formError },
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(forgotPasswordFormSchema, {});

  const onSubmit = handleSubmit(
    useCallback(async ({ email }) => {
      await forgotPassword(email);
      setVerifyNewPassword(true);
    }, []),
  );

  if (verifyNewPassword && !!values.email) {
    return <VerifyNewPassword email={values.email} />;
  }

  return (
    <ModalPage subtitle="Forgotten password" title="Verify account">
      {submitting && <LoadingModal />}

      <Container size="small">
        <FormSet on_submit={onSubmit} vertical>
          <FormRow>
            <Input
              autoFocus
              label="Fill in your email address so we can send you a verification code."
              placeholder="mail@example.com"
              size="large"
              stretch
              type="email"
              {...register.input('email')}
            />
          </FormRow>
          <FormRow top="large">
            <Button
              disabled={submitting}
              icon={SendMediumIcon}
              icon_position="left"
              icon_size="medium"
              size="large"
              status={formError}
              type="submit"
            >
              Send verification code
            </Button>
          </FormRow>
        </FormSet>
      </Container>
    </ModalPage>
  );
}
