import { Button, FormRow, FormSet, Input, P } from '@dnb/eufemia';
import { Field } from '@dnb/eufemia/extensions/forms';
import { padlock_medium as PadlockMediumIcon } from '@dnb/eufemia/icons';
import { PASSWORD_MATCHER } from '@portals/shared/common/matchers';
import { INVALID_PASSWORD } from '@portals/shared/common/messages';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { hasOwnProperty } from '@portals/shared-frontend/utils';
import { type JSX, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { confirmPassword } from '@/auth/cognito';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import ModalPage from '@/components/ModalPage';

const resetPasswordFormSchema = z.object({
  code: z.string(),
  password: z.string().regex(PASSWORD_MATCHER, INVALID_PASSWORD),
  passwordConfirm: z.string().regex(PASSWORD_MATCHER, INVALID_PASSWORD),
});

type VerifyNewPasswordProps = {
  email: string;
};

export default function VerifyNewPassword({
  email,
}: VerifyNewPasswordProps): JSX.Element {
  const navigate = useNavigate();

  const [passwordConfirmed, setPasswordConfirmed] = useState<boolean>(false);
  const {
    controller: { formError, setError, setValue, errors },
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(resetPasswordFormSchema, {});

  const onSubmit = handleSubmit(
    useCallback(
      async ({ code, password, passwordConfirm }) => {
        if (password !== passwordConfirm) {
          setError('password', 'Passwords do not match');
          setError('passwordConfirm', 'Passwords do not match');
          return;
        }

        try {
          await confirmPassword({
            username: email,
            password: passwordConfirm,
            verification: code,
          });
          setPasswordConfirmed(true);
        } catch (error) {
          if (!hasOwnProperty(error, 'code')) {
            throw error;
          }

          switch (error.code) {
            case 'CodeMismatchException': {
              setError('code', 'Invalid verification code');
              break;
            }
            case 'ExpiredCodeException': {
              setError('code', 'Verification code has expired');
              break;
            }
            default: {
              throw error;
            }
          }
        }
      },
      [email, setError],
    ),
  );

  const title = passwordConfirmed
    ? 'New password is saved'
    : 'Verify account & create a new password';

  return (
    <ModalPage
      description={
        !passwordConfirmed && (
          <>
            <P bottom="small">
              Check your mail for the verification code and fill it in. Then
              create your new password.
            </P>
            <P>
              The password must contain a minimum of 8 characters, one uppercase
              and one lowercase letter. You also need a number and a special
              character.
            </P>
          </>
        )
      }
      subtitle="Forgotten password"
      title={title}
    >
      {submitting && <LoadingModal />}
      {passwordConfirmed ? (
        <Container size="small">
          <Button onClick={() => navigate('/login')} size="large">
            Go to developer portal
          </Button>
        </Container>
      ) : (
        <Container size="small">
          <FormSet on_submit={onSubmit} vertical>
            <FormRow>
              <Input
                autoFocus
                label="Verification code"
                placeholder="xxxxxx"
                size="large"
                stretch
                {...register.input('code')}
              />
            </FormRow>
            <FormRow top="medium">
              <Field.Password
                error={
                  errors['password'] ? new Error(errors['password']) : undefined
                }
                label="New password"
                onChange={(value) => setValue('password', value)}
                required
                size="large"
                width="stretch"
              />
            </FormRow>
            <FormRow top="medium">
              <Field.Password
                error={
                  errors['password'] ? new Error(errors['password']) : undefined
                }
                label="Confirm new password"
                onChange={(value) => setValue('passwordConfirm', value)}
                required
                size="large"
                width="stretch"
              />
            </FormRow>

            <FormRow top="large">
              <Button
                disabled={submitting}
                icon={PadlockMediumIcon}
                icon_position="left"
                icon_size="medium"
                size="large"
                status={formError}
                type="submit"
              >
                Save new password
              </Button>
            </FormRow>
          </FormSet>
        </Container>
      )}
    </ModalPage>
  );
}
