import { EventSchemaResponse } from '@/openapi/types';
import { toJsonMarkdown } from '@/utils/markdownUtils';
import { capitalizeFirstLetterOfString } from '@/utils/textUtils';

export function getSchemaMarkdown(schema: EventSchemaResponse): string {
  const header = `# ${capitalizeFirstLetterOfString(schema.type)} Schema`;
  const format = `Schema format: \`${schema.format}\``;
  const content = toJsonMarkdown(JSON.stringify(schema.schema, null, '\t'));
  return header + '\n' + format + '\n' + content;
}
