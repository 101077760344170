import { motion } from 'motion/react';

type Position = {
  x: number;
  y: number;
  animate: boolean;
  durationOfDuckMove: number;
};

export const TheDuck = ({ x, y, animate, durationOfDuckMove }: Position) => {
  return (
    <motion.svg
      animate={
        animate
          ? {
              x: [
                x - 180,
                x - 180 + 10,
                x - 180 - 10,
                x - 180 + 5,
                x - 180 - 5,
                x - 180,
              ], // Adding wiggle to x position
              y: [
                y - 100,
                y - 100 + 10,
                y - 100 - 10,
                y - 100 + 5,
                y - 100 - 5,
                y - 100,
              ], // Adding wiggle to y position
            }
          : {
              transition: { duration: durationOfDuckMove / 1000 },
              x: x - 180,
              y: y - 100,
            }
      }
      fill="none"
      height="175"
      initial="hidden"
      viewBox="0 0 181 175"
      width="181"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.373 32.6249L163 92L161 147.5L61.3342 86.195L65.373 32.6249Z"
        fill="#007272"
      />
      <path
        d="M61.4736 85.7493L161 147L110.394 170.021L4.02327 108.355L61.4736 85.7493Z"
        fill="#007272"
      />
      <rect
        fill="#14555A"
        height="53.7221"
        transform="matrix(0.865133 0.501543 -0.0751802 0.99717 8.06055 54.785)"
        width="122.953"
      />
      <rect
        fill="#007272"
        height="61.7379"
        transform="matrix(0.865133 0.501543 -0.930552 0.366159 65.5127 32.179)"
        width="122.953"
      />
      <path
        d="M125.5 15.5L169.206 41.0478L164.039 95.1968L113.307 59.0747L125.5 15.5Z"
        fill="#007272"
      />
      <rect
        fill="#007272"
        height="54.395"
        transform="matrix(0.814605 0.580015 -0.909611 0.415461 113.438 58.6251)"
        width="61.632"
      />
      <path
        d="M68.4995 36.0001L119.858 63.1969L114.69 117.346L64.5 87.5L68.4995 36.0001Z"
        fill="#14555A"
      />
      <path
        d="M125.499 15.4994L168.999 40.9995L120.707 63.8L68.5 36L125.499 15.4994Z"
        fill="#007272"
      />
      <path
        d="M151 76.5L171.999 84.9999L172.999 96.4999L150.384 87.339L151 76.5Z"
        fill="#FF5400"
      />
      <path
        d="M149.909 86.7148L172.999 96.5L154.497 101.434L130.216 96.3026L149.909 86.7148Z"
        fill="#FF5400"
      />
      <path
        d="M128.478 82.1863L151.5 91L154.749 101.488L130.214 96.3031L128.478 82.1863Z"
        fill="#D94E09"
      />
      <path
        d="M151.001 76.4999L172 84.9999L151.5 91.4999L128.479 82.1862L151.001 76.4999Z"
        fill="#FF5400"
      />
      <path
        d="M127 68.5729C127 67.3021 128.013 65.7687 129.09 65.4095L137.935 62.4585C138.736 62.1913 139.309 62.6765 139.309 63.6219L139.309 71.6248C139.309 72.8655 138.341 74.3658 137.282 74.7659L128.437 78.108C127.607 78.4218 127 77.9401 127 76.9669L127 68.5729Z"
        fill="black"
      />
      <path
        d="M152 60.817C152 59.5463 153.013 58.0129 154.09 57.6536L161.626 55.1394C162.427 54.8721 163 55.3574 163 56.3028L163 63.0295C163 64.2702 162.032 65.7705 160.973 66.1706L153.437 69.018C152.607 69.3318 152 68.8502 152 67.877L152 60.817Z"
        fill="black"
      />
    </motion.svg>
  );
};
