import { Link, P } from '@dnb/eufemia';
import type {
  ApiDto,
  ApiWithStatusAndScopesDto,
} from '@portals/shared/portal/ApiDto';
import { SpectralDiagnosticsDto } from '@portals/shared/portal/ReleaseDto';
import type { ReactNode } from 'react';

export type ApiErrorKeys = keyof ApiValidationRequirement;
export type ValidationKey = keyof ApiError;
export type ApiValidationRequirement = {
  format?: string;
  duplicate?: string;
  suffix?: string;
  exists?: string;
};

export type ApiError = {
  name: ApiValidationRequirement | null;
  description: ApiValidationRequirement | null;
  guide: ApiValidationRequirement | null;
  reference: ApiValidationRequirement | null;
};

const defaultError: ApiError = {
  name: null,
  description: null,
  guide: null,
  reference: null,
};
export const API_NAME_REGEX = new RegExp(
  /^([A-Z][\dA-Za-z]*)(\s[A-Z][\dA-Za-z]*)*$/,
);
export const API_NAME_SUFFIX_REGEX = new RegExp(/^(?!.*api$)/i);

export function validateApiName(
  api: ApiWithStatusAndScopesDto,
  apis: ApiDto[],
  error: ApiError,
): ApiError {
  if (!API_NAME_REGEX.test(api.name)) {
    error.name = {
      ...error.name,
      format: 'Be sure to follow the suggested naming format',
    };
  }
  if (!API_NAME_SUFFIX_REGEX.test(api.name)) {
    error.name = {
      ...error.name,
      suffix: `Make sure your API name is not suffix with 'api'`,
    };
  }
  const apisWithSameName = apis.filter(
    ({ name, classification }) =>
      name.toLocaleLowerCase() === api.name.toLocaleLowerCase() &&
      classification === api.classification,
  );
  if (apisWithSameName.length > 1) {
    error.name = {
      ...error.name,
      duplicate: `There is already an ${api.classification} API with the with the name ${api.name}. Make sure to use use unique names for each API within the different API classifications.`,
    };
  }
  return error;
}

export function validateApiDescription(
  api: ApiWithStatusAndScopesDto,
  error: ApiError,
) {
  return api.description && api.description.length > 30
    ? { ...error }
    : {
        ...error,
        description: {
          exists: 'Make sure to provide a proper description of your API.',
        },
      };
}

export function validateGuide(guide: string | null, error: ApiError) {
  const errorMessage = `Make sure your Guide is present, and contains the mandatory parts 'Introduction', 'Getting Started' and 'Contact'.`;

  if (!guide) {
    return {
      ...error,
      guide: {
        exists: errorMessage,
      },
    };
  }

  const lcGuide = guide.toLowerCase();
  const GETTING_STARTED = 'getting started';
  const INTRODUCTION = 'introduction';
  const CONTACT = 'contact';

  if (
    lcGuide.includes(GETTING_STARTED) &&
    lcGuide.includes(INTRODUCTION) &&
    lcGuide.includes(CONTACT)
  ) {
    return { ...error };
  }
  return {
    ...error,
    guide: {
      exists: errorMessage,
    },
  };
}
export function validateReference(
  reference: unknown | null,
  error: ApiError,
  spectralDiagnostics: SpectralDiagnosticsDto | null,
) {
  if (reference === null) {
    return {
      ...error,
      reference: {
        ...error.reference,
        exists: 'Make sure to upload an API reference.',
        format:
          'Make sure the reference is free of any spectral diagnostics errors.',
      },
    };
  } else if (
    !spectralDiagnostics ||
    spectralDiagnostics?.spectralStrictDiagnostics?.length > 0
  ) {
    return {
      ...error,
      reference: {
        format:
          'Make sure the reference is free of any spectral diagnostics errors.',
      },
    };
  } else {
    return { ...error };
  }
}

export function validateApi(
  api: ApiWithStatusAndScopesDto,
  apis: ApiDto[],
  reference: unknown | null,
  guide: string | null,
  spectralDiagnostics: SpectralDiagnosticsDto | null,
): ApiError {
  const name = validateApiName(api, apis, { ...defaultError });
  const description = validateApiDescription(api, name);
  const guideValidation = validateGuide(guide, description);
  return validateReference(reference, guideValidation, spectralDiagnostics);
}

type ValidationSectionInput = {
  inputData: ApiValidationRequirement;
  errorKey: ValidationKey;
  errors: ApiError;
  title: string;
  additionalInformation?: ReactNode;
};

export const validationData = (
  api: ApiWithStatusAndScopesDto,
  apis: ApiDto[],
  reference: unknown | null,
  guide: string | null,
  spectralDiagnostics: SpectralDiagnosticsDto | null,
): ValidationSectionInput[] => {
  const errors = validateApi(api, apis, reference, guide, spectralDiagnostics);
  return [
    {
      inputData: {
        format: `Publish API name in "Account Information Service" format`,
        suffix: 'API name should not be suffixed with "API"',
        duplicate:
          'API naming and functionality is not a duplicate/copy of existing APIs with the same classification.',
      },
      errorKey: 'name',
      errors: errors,
      title: 'API Name',
    },
    {
      inputData: {
        exists: `API description is understandable and differentiating from other APIs.`,
      },
      errorKey: 'description',
      errors: errors,
      title: 'API Description',
    },
    {
      inputData: {
        exists: `Contains mandatory parts: Introduction, Getting started, Contact us`,
      },
      errorKey: 'guide',
      errors: errors,
      title: 'API Guide',
    },
    {
      additionalInformation: (
        <>
          <P top="x-small">
            Make sure it is according to{' '}
            <Link
              href="https://dnb-asa.atlassian.net/wiki/spaces/KKO/pages/113049922/REST+API+Guidelines"
              target="_blank"
            >
              DNB API Guidelines
            </Link>
          </P>
          <P top="x-small">
            Check api specs lint errors on{' '}
            <Link
              href={`/documentation/${api.slug}/@default/@latest/reference?view=spectral-diagnostics`}
              target="_blank"
            >
              api reference page
            </Link>
          </P>
        </>
      ),

      inputData: {
        exists: `Is present and according to OpenAPI standard`,
        format: `Free of any lint errors`,
      },
      errorKey: 'reference',
      errors: errors,
      title: 'API Reference',
    },
  ];
};
