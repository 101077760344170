/* eslint-disable max-lines */
import { H3 } from '@dnb/eufemia';
import { shield_medium as ShieldIcon } from '@dnb/eufemia/icons';
import type {
  ApiKeyDto,
  CreateApiKeyRequestDto,
} from '@portals/shared/portal/ApiKeyDto';
import {
  AccessTypes,
  type AppWithEntitlementsDto,
} from '@portals/shared/portal/AppDto';
import { useAsync } from '@portals/shared-frontend/hooks';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';
import { useEffect } from 'react';
import { string, z } from 'zod';

import { createApiKey, deleteApiKey } from '@/api/app';
import LoadingModal from '@/components/LoadingModal';
import useFeatureFlags from '@/hooks/useFeatureFlags';

import { ApiKeysCard } from './components/ApiKeysCard';
import { ClientCard } from './components/ClientCard';

interface CredentialProps {
  app: AppWithEntitlementsDto;
  isAdmin: boolean;
  onApiKeyCreated: (apiKey: ApiKeyDto) => void;
  onApiKeyDeleted: (apiKeyId: string) => void;
  onClientDeleted: (clientId: string) => void;
  onSetError: (error: string) => void;
  error: string | undefined;
}

export const createApiKeyFormSchema = z.object({
  mode: string(),
});

export default function Credential({
  app,
  isAdmin,
  onApiKeyCreated,
  onApiKeyDeleted,
  onClientDeleted,
  onSetError,
  error,
}: CredentialProps) {
  const { featureFlags, isLoading: flagsLoading } = useFeatureFlags();

  const onCreateApiKey = useAsync(
    async (data: CreateApiKeyRequestDto) => {
      try {
        if (app) {
          const apiKey = await createApiKey(app.id, data);
          onApiKeyCreated(apiKey);
        }
      } catch (error) {
        if (ApiError.isApiError(error)) {
          onSetError(error.message);
        } else {
          onSetError('Something went wrong creating the API key.');
        }
      }
    },
    [app, onApiKeyCreated, onSetError],
  );

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        onSetError('');
      }, 3500);
    }
  }, [error, onSetError]);

  const onDeleteApiKey = useAsync(
    async (apiKey: ApiKeyDto) => {
      if (app) {
        await deleteApiKey(app.id, apiKey.key);
        onApiKeyDeleted(apiKey.id);
      }
    },
    [app, onApiKeyDeleted],
  );

  return (
    <>
      {(onCreateApiKey.waiting || onDeleteApiKey.waiting || flagsLoading) && (
        <LoadingModal />
      )}

      <H3 bottom="medium">
        <ShieldIcon /> Credentials
      </H3>

      {app.accessType === AccessTypes.API_KEYS ? (
        <ApiKeysCard
          app={app}
          error={error}
          isAdmin={isAdmin}
          onApiKeyCreated={onApiKeyCreated}
          onApiKeyDeleted={onApiKeyDeleted}
          onSetError={onSetError}
        />
      ) : (
        <ClientCard
          app={app}
          featureFlags={featureFlags}
          isAdmin={isAdmin}
          onClientDeleted={onClientDeleted}
        />
      )}
    </>
  );
}
