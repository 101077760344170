import { Button } from '@dnb/eufemia';
import type { SpacingElementProps } from '@dnb/eufemia/shared/types';
import type { JSX, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

type BackButtonProps = SpacingElementProps &
  PropsWithChildren<{
    to: string;
  }>;

export default function BackButton({
  to,
  children,
  ...props
}: BackButtonProps): JSX.Element {
  return (
    <Button
      element={Link}
      icon="arrow_left"
      icon_position="left"
      to={to}
      variant="tertiary"
      {...props}
    >
      {children}
    </Button>
  );
}
