import type { JSX } from 'react';

import Logo from './logo.svg';
import LogoOriginal from './logooriginal.svg';

export default function DeveloperLogo(): JSX.Element {
  return <Logo />;
}

export function DeveloperOriginalLogo(): JSX.Element {
  return <LogoOriginal />;
}
