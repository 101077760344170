import { isInternalUser } from '@portals/shared-frontend/utils';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import CenteredProgressIndicator from '@/components/ProgressIndicator';
import useReturnTo from '@/hooks/useReturnTo';
import { useUser } from '@/hooks/useUser';

/**
 * Requires that the user is logged in as a DNB user before displaying the protected resource.
 *
 * Redirects to login page if the user is not logged in.
 * Redirects to the 404 page if the user is logged in, but not a DNB user.
 */
export default function InternalRoutes() {
  const { isLoading, isLoggedIn, user } = useUser();
  const { pathname } = useLocation();
  const [, setReturnTo] = useReturnTo();

  if (isLoading) return <CenteredProgressIndicator />;

  if (!isLoggedIn) {
    setReturnTo(pathname);
    return <Navigate to="/login" />;
  }

  return isInternalUser(user?.email) ? <Outlet /> : <Navigate to="/404" />;
}
