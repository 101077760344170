import { hasOwnProperty } from '@portals/shared-frontend/utils';
import { useSWRConfig } from 'swr';

type SWRConfig = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError: (error: unknown, key: string, config: any) => void;
};

/**
 * Hook that returns config for SWR which will handle 404 responses locally while forwarding other responses to the
 * global handler.
 */
export default function useSWRExpect404(): SWRConfig {
  const { onError } = useSWRConfig();
  return {
    onError: (error, key, config) => {
      if (hasOwnProperty(error, 'status') && error.status === 404) return;
      onError(error, key, config);
    },
  };
}
