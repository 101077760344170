import { GlobalError, H2, P } from '@dnb/eufemia';
import type { ApiDto } from '@portals/shared/portal/ApiDto';
import type { JSX } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import Container from '@/components/Container';
import Page from '@/components/Page';

export default function ApiTermsOfUse(): JSX.Element {
  const { apiSlug } = useParams();

  const { data: apis, isValidating: apisLoading } = useSWR<ApiDto[]>(`/api`);
  const api = apis?.find(({ slug }) => slug === apiSlug);

  if (!apisLoading && !apis && !api) {
    return <GlobalError status="404" />;
  }

  return (
    <Page skeleton={apisLoading} subtitle="Terms of use" title={api?.name}>
      <Container size="small">
        <H2>{api?.name} API - Terms of use</H2>
        {api?.termsOfUseUpdatedAt && (
          <P size="x-small" top="small">
            Last updated: {new Date(api.termsOfUseUpdatedAt).toUTCString()}
          </P>
        )}
        <P top="medium">
          {api?.termsOfUse ??
            `The ${api?.name} does not have terms of use defined`}
        </P>
      </Container>
    </Page>
  );
}
