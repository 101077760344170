import { Button, Dialog, H3, P, Space } from '@dnb/eufemia';
import { edit as EditIcon, file_medium as FileIcon } from '@dnb/eufemia/icons';
import { copy as CopyIcon } from '@dnb/eufemia/icons';
import type { ProjectDto } from '@portals/shared/admin/ProjectDto';
import type { JSX } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from '@/components/Card';
import CopyButton from '@/components/CopyButton';
import Divider from '@/components/Divider';

import style from './index.module.css';

type AppOwner = {
  id: string;
  name: string;
  type: 'team' | 'personal';
};

type AppDetailsProps = {
  appId: string;
  appName: string;
  appDescription: string | null;
  owner: AppOwner;
  project: ProjectDto | null;
  createdBy?: string;
  isAdmin: boolean;
};

export default function ApplicationDetails({
  appId,
  appName,
  appDescription,
  project,
  owner,
  createdBy,
  isAdmin,
}: AppDetailsProps): JSX.Element {
  const navigate = useNavigate();

  return (
    <>
      <H3 bottom="small">
        <FileIcon /> App details
      </H3>
      <Card>
        <Space bottom="small" className={style['AppDetails-CardRow']}>
          <P bold className={style['AppDetails-Label']}>
            App-Id
          </P>
          <P>{appId}</P>
          <CopyButton icon={CopyIcon} toCopy={appId} variant="tertiary">
            Copy
          </CopyButton>
        </Space>
        {project?.name && (
          <Space bottom="small" className={style['AppDetails-CardRow']}>
            <P bold className={style['AppDetails-Label']}>
              Project
            </P>
            <P>{project?.name}</P>
            <Dialog
              description={
                <P>
                  An project is an entity that maps to ciam applications. Its
                  primary purpose is to group clients for the purpose of
                  effective tracking.
                </P>
              }
              title="Project"
            >
              <Space
                bottom="small"
                className={style['AppDetails-CardRow']}
                top="small"
              >
                <P bold className={style['AppDetails-Label']}>
                  Project id
                </P>
                <P>{project.id}</P>
              </Space>
              <Space bottom="small" className={style['AppDetails-CardRow']}>
                <P bold className={style['AppDetails-Label']}>
                  Project Name
                </P>
                <P>{project.name}</P>
              </Space>
              <Space bottom="small" className={style['AppDetails-CardRow']}>
                <P bold className={style['AppDetails-Label']}>
                  Project Description
                </P>
                <P>{project.description}</P>
              </Space>
              <Space bottom="small" className={style['AppDetails-CardRow']}>
                <P bold className={style['AppDetails-Label']}>
                  Ciam Uat App Id
                </P>
                <P>{project.ciamUatAppId}</P>
              </Space>
              <Space bottom="small" className={style['AppDetails-CardRow']}>
                <P bold className={style['AppDetails-Label']}>
                  Ciam Prod App Id
                </P>
                <P>{project.ciamProdAppId}</P>
              </Space>
            </Dialog>
          </Space>
        )}
        <Space bottom="small" className={style['AppDetails-CardRow']}>
          <P bold className={style['AppDetails-Label']}>
            Owner
          </P>
          <P>{owner.name}</P>
        </Space>
        <Space bottom="small" className={style['AppDetails-CardRow']}>
          <P bold className={style['AppDetails-Label']}>
            App name
          </P>
          <P>{appName}</P>
        </Space>
        {createdBy && (
          <Space bottom="small" className={style['AppDetails-CardRow']}>
            <P bold className={style['AppDetails-Label']}>
              Created by
            </P>
            <P>{createdBy}</P>
          </Space>
        )}
        <Space bottom="small" className={style['AppDetails-CardRow']}>
          <P bold className={style['AppDetails-Label']}>
            App description
          </P>
          <P>{appDescription ?? 'No description provided'}</P>
        </Space>
        <Divider />
        {isAdmin && (
          <div>
            <Button
              icon={EditIcon}
              icon_position="left"
              on_click={() => {
                navigate(
                  owner.type === 'team'
                    ? `/team/${owner.id}/application/${appId}/edit`
                    : `/application/${appId}/edit`,
                  {
                    replace: true,
                  },
                );
              }}
              text="Edit"
              top="small"
              variant="secondary"
            />
          </div>
        )}
      </Card>
    </>
  );
}
