import {
  Button,
  Dialog,
  FormRow,
  FormSet,
  FormStatus,
  H4,
  P,
  Radio,
  Space,
} from '@dnb/eufemia';
import type {
  ApiKeyDto,
  CreateApiKeyRequestDto,
} from '@portals/shared/portal/ApiKeyDto';
import { type AppWithEntitlementsDto } from '@portals/shared/portal/AppDto';
import { useAsync, useEufemiaForm } from '@portals/shared-frontend/hooks';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';
import { useCallback } from 'react';
import { string, z } from 'zod';

import { createApiKey, deleteApiKey } from '@/api/app';
import Card from '@/components/Card';
import Divider from '@/components/Divider';

import Apikey from '../../ApiKey';

import style from '../index.module.css';

interface Props {
  isAdmin: boolean;
  app: AppWithEntitlementsDto;
  onApiKeyCreated: (apiKey: ApiKeyDto) => void;
  onApiKeyDeleted: (apiKeyId: string) => void;
  onSetError: (error: string) => void;
  error: string | undefined;
}

export const createApiKeyFormSchema = z.object({
  mode: string(),
});

export function ApiKeysCard({
  app,
  isAdmin,
  onApiKeyCreated,
  onApiKeyDeleted,
  onSetError,
  error,
}: Props) {
  const liveApiKeys = app?.apiKeys.filter(({ liveMode }) => liveMode);
  const testApiKeys = app?.apiKeys.filter(({ liveMode }) => !liveMode);

  const onDeleteApiKey = useAsync(
    async (apiKey: ApiKeyDto) => {
      if (app) {
        await deleteApiKey(app.id, apiKey.key);
        onApiKeyDeleted(apiKey.id);
      }
    },
    [app, onApiKeyDeleted],
  );

  const onCreateApiKey = useAsync(
    async (data: CreateApiKeyRequestDto) => {
      try {
        if (app) {
          const apiKey = await createApiKey(app.id, data);
          onApiKeyCreated(apiKey);
        }
      } catch (error) {
        if (ApiError.isApiError(error)) {
          onSetError(error.message);
        } else {
          onSetError('Something went wrong creating the API key.');
        }
      }
    },
    [app, onApiKeyCreated, onSetError],
  );

  return (
    <Card>
      <FormStatus
        state="info"
        text={
          <>
            API keys should be treated with the same level of confidentiality as
            passwords, and should not be shared or exposed to unauthorized
            parties.
          </>
        }
      />
      <Space
        bottom="small"
        className={style['Credential-Container']}
        top="small"
      >
        <H4>Live keys</H4>
        <Space className={style['Credential-Card']}>
          {liveApiKeys.map((apiKey) => (
            <Apikey
              apiKey={apiKey}
              isAdmin={isAdmin}
              key={apiKey.id}
              onDeleteClicked={() => onDeleteApiKey.execute(apiKey)}
            />
          ))}
        </Space>

        {!app.isProviderApp && (
          <>
            <H4 bottom="0" top="medium">
              Test keys
            </H4>
            <Space className={style['Credential-Card']}>
              {testApiKeys.map((apiKey) => (
                <Apikey
                  apiKey={apiKey}
                  isAdmin={isAdmin}
                  key={apiKey.id}
                  onDeleteClicked={() => onDeleteApiKey.execute(apiKey)}
                />
              ))}
            </Space>
          </>
        )}

        {app?.apiKeys.length === 0 && <P>You currently have no API keys.</P>}
      </Space>
      <Divider />
      {isAdmin && (
        <Space top="small">
          <Dialog
            title="New API key"
            triggerAttributes={{
              variant: 'primary',
              icon: 'add',
              text: 'New key',
            }}
          >
            <CreateApiKeyForm
              isProviderApp={app.isProviderApp}
              onFormSubmit={async (data) => await onCreateApiKey.execute(data)}
            />
          </Dialog>
        </Space>
      )}
      {error && <FormStatus text={error} top="medium" />}
    </Card>
  );
}

type CreateApiKeyForm = {
  onFormSubmit: (data: CreateApiKeyRequestDto) => void;
  isProviderApp: boolean;
};

const CreateApiKeyForm = ({
  onFormSubmit,
  isProviderApp,
}: CreateApiKeyForm) => {
  const { register, handleSubmit, controller } = useEufemiaForm(
    createApiKeyFormSchema,
    isProviderApp ? { mode: 'live' } : {},
  );
  const onSubmit = handleSubmit(
    useCallback(
      async ({ mode }) => {
        onFormSubmit({ liveMode: mode == 'live' });
      },
      [onFormSubmit],
    ),
  );
  return (
    <FormSet bottom="0" on_submit={onSubmit} vertical>
      <FormRow>
        <Radio.Group
          {...register.radioGroup('mode')}
          bottom="large"
          label="What environment is the key for?"
          layout_direction="column"
        >
          <Radio
            disabled={isProviderApp}
            label="Test"
            top="small"
            value="test"
          />
          <Radio label="Live" top="small" value="live" />
        </Radio.Group>
      </FormRow>
      <Dialog.Action>
        <Button
          on_click={({ close }) => {
            close();
          }}
          text="Cancel"
          variant="secondary"
        />
        <Button
          on_click={({ close }) => {
            if (controller.values.mode) {
              close();
            }
          }}
          text="Create key"
          type="submit"
        />
      </Dialog.Action>
    </FormSet>
  );
};
