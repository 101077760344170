import {
  Button,
  FormLabel,
  FormRow,
  FormSet,
  FormStatus,
  P,
  Textarea,
  ToggleButton,
} from '@dnb/eufemia';
import { feedbackInputSchema } from '@portals/shared/portal/FeedbackDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { type JSX, useCallback, useEffect, useState } from 'react';

import { submitFeedback } from '@/api/feedback';
import Container from '@/components/Container';

export default function FeedbackModule(): JSX.Element {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    controller,
    register,
    controller: { formError, setValue },
    submitting,
    handleSubmit,
  } = useEufemiaForm(feedbackInputSchema, {});

  function onSelect(value: string) {
    setValue('helpful', value === 'yes');
  }

  useEffect(() => {
    if (controller.values.helpful !== undefined) {
      setValue('path', window.location.pathname + window.location.hash);
    }
  }, [controller.values.helpful, setValue]);

  const onSubmit = handleSubmit(
    useCallback(async ({ path, message, helpful }) => {
      await submitFeedback({
        path,
        message,
        helpful,
      });
      setIsSubmitted(true);
    }, []),
  );

  return (
    <Container size="small" top="x-large">
      {!isSubmitted && (
        <FormSet on_submit={onSubmit} vertical>
          <FormLabel>Did you find this page helpful?</FormLabel>
          <FormRow top="small" vertical={false}>
            <ToggleButton.Group
              {...register.toggleButton('helpful')}
              on_change={({ value }) => {
                onSelect(value);
              }}
            >
              <ToggleButton
                bottom="small"
                right="small"
                text="Yes"
                value="yes"
                variant="radio"
              />
              <ToggleButton text="No" value="no" variant="radio" />
            </ToggleButton.Group>
          </FormRow>
          {controller.values.helpful !== undefined && (
            <div>
              <FormRow top="small">
                {controller.values.helpful && (
                  <P>
                    We are glad we could help!
                    <br />
                    Anything else we should know?
                  </P>
                )}
                {!controller.values.helpful && (
                  <P>
                    Thanks for letting us know.
                    <br />
                    Please let us know how we can improve:
                  </P>
                )}
                <Textarea
                  placeholder="Optional: Provide your feedback here..."
                  rows="3"
                  stretch
                  top="medium"
                  type="text"
                  {...register.textarea('message')}
                />
              </FormRow>
              <FormRow>
                <Button
                  disabled={submitting}
                  size="large"
                  status={formError}
                  top="small"
                  type="submit"
                >
                  Send feedback
                </Button>
              </FormRow>
            </div>
          )}
        </FormSet>
      )}
      {isSubmitted && (
        <FormStatus size="large" state="info" top="medium" variant="outlined">
          <P>Thank you for your feedback!</P>
        </FormStatus>
      )}
    </Container>
  );
}
