import { useAsync } from '@portals/shared-frontend/hooks';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';

import { createApiFavourite, deleteApiFavourite } from '@/api/user';

import FavouriteButton from '../FavouriteButton';

interface ApiFavouriteProps {
  apiId: string;
  isFavorite: boolean | undefined;
  onChange: (apiId: string, isFavorite: boolean) => void;
}

export default function ApiFavouriteButton({
  apiId,
  isFavorite,
  onChange,
}: ApiFavouriteProps) {
  const onClickApiFavourite = useAsync(async () => {
    try {
      if (isFavorite) {
        await deleteApiFavourite(apiId);
      } else {
        await createApiFavourite(apiId);
      }
      onChange(apiId, !isFavorite);
    } catch (error) {
      if (!ApiError.isApiError(error)) {
        throw error;
      }
    }
  }, [apiId, isFavorite, onChange]);

  return (
    <FavouriteButton
      isFavourite={isFavorite || false}
      isLoading={onClickApiFavourite.waiting}
      onClick={() => onClickApiFavourite.execute()}
    />
  );
}
