import { type JSX, useMemo } from 'react';

import Guide from '@/components/Guide';
import { useBackend } from '@/hooks/useBackend';
import useSWRExpect404 from '@/hooks/useSWRExpect404';

import EventResourceNotFoundError from '../EventResourceNotFoundError';
import { getSchemaMarkdown } from './SchemaViewAdditions';

export interface Props {
  environmentSlug: string;
  eventSlug: string;
  releaseVersion: string;
  topChild: JSX.Element;
}

export default function SchemaView({
  environmentSlug,
  eventSlug,
  releaseVersion,
  topChild,
}: Props) {
  const swrExpect404 = useSWRExpect404();
  const {
    data: schemas,
    isValidating: schemasValidation,
    error,
  } = useBackend(
    `/events/{eventSlug}/environments/{environmentSlug}/releases/{releaseVersion}/schemas`,
    {
      params: {
        path: {
          eventSlug: eventSlug,
          environmentSlug: environmentSlug,
          releaseVersion: releaseVersion,
        },
      },
    },
    swrExpect404,
  );

  const markdownGuide = useMemo(
    () => schemas?.map(getSchemaMarkdown).join('\n'),
    [schemas],
  );

  if (error) {
    return <EventResourceNotFoundError type="schema" />;
  }

  if (schemas?.length === 0 && !schemasValidation) {
    return <EventResourceNotFoundError type="version" />;
  }

  return (
    <Guide
      guide={markdownGuide}
      hideSearch
      loading={schemasValidation}
      style={{ paddingTop: '0' }}
      topChild={topChild}
    />
  );
}
