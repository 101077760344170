import { cloud, play } from '@dnb/eufemia/icons';
import { isInternalUser } from '@portals/shared-frontend/utils';

import Tile from '@/components/Tile';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { useUser } from '@/hooks/useUser';

import ServicePage from '../../components/ServicePage';

export default function ServiceExplorer() {
  const { user } = useUser();
  const isDnbUser = isInternalUser(user?.email);
  const { featureFlags } = useFeatureFlags();
  return (
    <ServicePage title="Service Explorer">
      <Tile
        description="See all APIs that you can connect to"
        icon={cloud}
        linkTo="/explorer/apis"
        title="Apis"
      />
      {isDnbUser && featureFlags.ENABLE_EVENT_PAGE && (
        <Tile
          description="See and connect to events"
          icon={play}
          linkTo="/explorer/events"
          title="Events"
        />
      )}
    </ServicePage>
  );
}
