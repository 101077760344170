import { Dereferenced } from '@portals/shared/common/open-api/types';
import Markdown from '@portals/shared-frontend/components/Markdown';
import type { OpenAPIV3 } from 'openapi-types';

import MediaTypeContent from '../MediaTypeContent';

import { H3S } from '@/pages/api-documentation/styles/Api.Reference.styles';

type RequestBodyProps = {
  data: Dereferenced<OpenAPIV3.RequestBodyObject>;
};

const RequestBody = ({ data }: RequestBodyProps) => {
  const { description, content } = data;

  return (
    <div>
      <H3S>Request body</H3S>
      {description && <Markdown>{description}</Markdown>}
      {content && Object.keys(content).length > 0 && (
        <MediaTypeContent content={content} schemaType="request" />
      )}
    </div>
  );
};

export default RequestBody;
