import { Badge, P, Section, Space } from '@dnb/eufemia';
import type { JSX } from 'react';

import { convertCamelCaseToTitleCase } from '@/utils';

import style from './index.module.css';

type ApiDetailsProps = {
  apiClassification: string;
  stage: string;
  apiDescription: string | null;
  slug: string;
};

export default function ApiDetails({
  apiClassification,
  stage,
  apiDescription,
  slug,
}: ApiDetailsProps): JSX.Element {
  return (
    <Section style_type="mint-green-25">
      <Space bottom="small" className={style['ApiDetails-CardRow']}>
        <P bold className={style['ApiDetails-Label']}>
          Classification
        </P>
        <Badge content={convertCamelCaseToTitleCase(apiClassification)} />
      </Space>

      <Space bottom="small" className={style['ApiDetails-CardRow']}>
        <P bold className={style['ApiDetails-Label']}>
          Stage
        </P>
        <Badge content={convertCamelCaseToTitleCase(stage)} />
      </Space>
      <Space bottom="small" className={style['ApiDetails-CardRow']}>
        <P bold className={style['ApiDetails-Label']}>
          Slug
        </P>
        <P>{slug}</P>
      </Space>
      <Space bottom="small" className={style['ApiDetails-CardRow']}>
        <P bold className={style['ApiDetails-Label']}>
          Description
        </P>
        <P>{apiDescription ?? 'No description provided'}</P>
      </Space>
    </Section>
  );
}
