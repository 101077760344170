/**
 * Deep copy that also handles circular dependencies.
 */
export function deepCopy<T>(obj: T, seen = new WeakMap()): T {
  // Handle null, undefined, primitive types, and functions
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  // If we've seen the object already, return its previously copied version
  if (seen.has(obj)) {
    return seen.get(obj);
  }

  // Create a new instance of the object (Array or Object)
  const copy = Array.isArray(obj) ? ([] as T) : ({} as T);

  // Store the current object in the WeakMap before copying its properties
  seen.set(obj, copy);

  // Recursively copy all properties of the object
  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      (copy as T)[key] = deepCopy((obj as T)[key], seen);
    }
  }

  return copy;
}

export function stringifyRemovingCyclicFields<T>(obj: T): string {
  const cache = new WeakSet();
  return JSON.stringify(obj, (_key, value) => {
    if (typeof value === 'object' && value !== null) {
      // Duplicate reference found, discard key
      if (cache.has(value)) return;
      cache.add(value);
    }
    return value;
  });
}

export function isStringArray(value: unknown): value is string[] {
  if (Array.isArray(value)) {
    return value.every((v) => typeof v === 'string');
  }
  return false;
}

export function isSetEqual(xs: Set<string>, ys: Set<string>) {
  return xs.size === ys.size && [...xs].every((x) => ys.has(x));
}
