import { PropsWithChildren } from 'react';

import HeroPage from '@/components/HeroPage';

import style from './index.module.css';

import ApiPageBanner from '@/illustrations/ApiPageBanner.png?url';

type ServicePageProps = PropsWithChildren<{
  title: string;
}>;

export default function ServicePage({ title, children }: ServicePageProps) {
  return (
    <HeroPage heroIllustration={ApiPageBanner} noContainer title={title}>
      <div className={style['ServicePageContent']} id="LandingContent">
        <div className={style['TileContainer']}>{children}</div>
      </div>
    </HeroPage>
  );
}
