import { Dialog, Div, P, Space, Span } from '@dnb/eufemia';
import { copy as CopyIcon, trash as TrashIcon } from '@dnb/eufemia/icons';
import type { ApiKeyDto } from '@portals/shared/portal/ApiKeyDto';
import type { JSX } from 'react';

import Card from '@/components/Card';
import CopyButton from '@/components/CopyButton';

import style from './index.module.css';

export type ApiKeyProps = {
  isAdmin: boolean;
  apiKey: ApiKeyDto;
  onDeleteClicked: () => void;
};

export default function Apikey({
  isAdmin,
  apiKey,
  onDeleteClicked,
}: ApiKeyProps): JSX.Element | null {
  return (
    <Card className={style['AppKeyTile']} key={apiKey.id}>
      <Space className={style['AppKeyTileContent']}>
        <Space>
          <P bottom="x-small">API key</P>
          <Span className={style['Subtext']}>
            {apiKey.liveMode
              ? `${'*'.repeat(6)}${apiKey.key.slice(-6)}`
              : apiKey.key}
          </Span>
        </Space>
      </Space>

      <Div className={style['AppKeyTileButtons']}>
        <CopyButton
          icon={CopyIcon}
          icon_position="left"
          key="copy"
          toCopy={apiKey.key}
          variant="secondary"
        >
          Copy key
        </CopyButton>
        {isAdmin && (
          <Dialog
            confirmText="Delete"
            confirmType="warning"
            declineText="Cancel"
            description="This action cannot be undone."
            icon={TrashIcon}
            onConfirm={({ close }) => {
              close();
              onDeleteClicked();
            }}
            title="Are you sure you want to delete this?"
            triggerAttributes={{
              text: 'Delete ',
              icon: TrashIcon,
              variant: 'tertiary',
            }}
            variant="confirmation"
          />
        )}
      </Div>
    </Card>
  );
}
