import HeroPage from '@/components/HeroPage';

import DeveloperPortalGuideContent from '../../../components/Guide/DeveloperPortalGuideContent';

import LandingPageBanner from '@/illustrations/LandingPageBanner.png?url';

export default function GettingStartedAPIConsumers() {
  return (
    <HeroPage
      heroIllustration={LandingPageBanner}
      noContainer
      noTopMargin
      subtitle="Follow these steps to start consuming APIs"
      title="Getting started for DNB internal API consumers"
    >
      <DeveloperPortalGuideContent contentSlug="consuming-apis" />
    </HeroPage>
  );
}
