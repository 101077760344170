import { Checkbox, Flex, P } from '@dnb/eufemia';
import { Dispatch, SetStateAction } from 'react';

import { removeStringFromArray } from '@/utils/arrayUtils';

import { EventWithUserDataResponseClassification as EventClassification } from '@/openapi/schema.d';

interface EventFilterProps {
  setActiveFilters: Dispatch<SetStateAction<string[]>>;
  activeFilters: string[];
}

export default function EventFilter({
  setActiveFilters,
  activeFilters,
}: EventFilterProps) {
  return (
    <Flex.Vertical>
      <P modifier="medium" space={{ bottom: 'x-small' }}>
        Event classification
      </P>
      <Checkbox
        checked={activeFilters.length === 0}
        label="All"
        onChange={({ checked }) =>
          checked
            ? setActiveFilters([])
            : setActiveFilters(removeStringFromArray(activeFilters, 'all'))
        }
      />
      <Checkbox
        checked={activeFilters.includes(EventClassification.DOMAIN)}
        label="Domain"
        onChange={({ checked }) =>
          checked
            ? setActiveFilters([...activeFilters, EventClassification.DOMAIN])
            : setActiveFilters(
                removeStringFromArray(
                  activeFilters,
                  EventClassification.DOMAIN,
                ),
              )
        }
      />
      <Checkbox
        checked={activeFilters.includes(EventClassification.EXPERIENCE)}
        label="Experience"
        onChange={({ checked }) =>
          checked
            ? setActiveFilters([
                ...activeFilters,
                EventClassification.EXPERIENCE,
              ])
            : setActiveFilters(
                removeStringFromArray(
                  activeFilters,
                  EventClassification.EXPERIENCE,
                ),
              )
        }
      />
      <Checkbox
        checked={activeFilters.includes(EventClassification.SYSTEM)}
        label="System"
        onChange={({ checked }) =>
          checked
            ? setActiveFilters([...activeFilters, EventClassification.SYSTEM])
            : setActiveFilters(
                removeStringFromArray(
                  activeFilters,
                  EventClassification.SYSTEM,
                ),
              )
        }
      />
    </Flex.Vertical>
  );
}
