import classnames from 'classnames';
import type { JSX } from 'react';

import { useScrollSpy } from '@/hooks/useScrollSpy';

import style from './index.module.css';

export interface ScrollMenuElement {
  title: string;
  id: string;
}

export interface ScrollMenuProps {
  elements: ScrollMenuElement[];
}

export function ScrollMenu({ elements }: ScrollMenuProps): JSX.Element {
  const ids = elements.map(({ id }) => id);
  const activeId = useScrollSpy(ids);

  return (
    <nav className={style['ScrollMenu']}>
      <ul>
        {elements.map(({ id, title }) => (
          <li key={id}>
            <a
              className={classnames({
                'dnb-tab-focus': true,
                [style['ScrollMenu-item']]: true,
                [style['ScrollMenu-item--active']]: activeId === id,
              })}
              href={`#${id}`}
            >
              {title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}
