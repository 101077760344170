import { Hr, P } from '@dnb/eufemia';
import { ApiDto } from '@portals/shared/portal/ApiDto';
import { PropsWithChildren, ReactNode } from 'react';

import Card from '@/components/Card';
import ApiTypeBadge from '@/pages/profile/components/ApiTypeBadge';

import style from './index.module.css';

interface Props {
  api: Pick<ApiDto, 'name' | 'type'>;
  headerContent?: ReactNode;
}

export default function ResourceCard({
  api,
  headerContent,
  children,
}: PropsWithChildren<Props>) {
  return (
    <Card>
      <div className={style['title-type']}>
        {headerContent ?? <P bold>{api.name}</P>}
        <ApiTypeBadge type={api.type} />
      </div>
      <Hr bottom="small" top="small" />
      {children}
    </Card>
  );
}
