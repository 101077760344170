/* eslint-disable max-lines */
import {
  Button,
  Card,
  Checkbox,
  Div,
  Dropdown,
  FormLabel,
  FormRow,
  FormSet,
  FormStatus,
  H2,
  HelpButton,
  Input,
  Textarea,
} from '@dnb/eufemia';
import { SeverityClassification } from '@portals/shared/portal/ApiDto';
import { createApiAccessTicketVariablesInputRequestSchema } from '@portals/shared/portal/SupportTicket';
import { useEufemiaForm } from '@portals/shared-frontend/hooks/useEufemiaForm';
import { anyTrue } from '@portals/shared-frontend/utils';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';
import { type JSX, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { requestLiveModeV2 } from '@/api/app';
import Divider from '@/components/Divider';
import ModalPage from '@/components/ModalPage';
import ServiceNowIdentifierViewer from '@/components/ServiceNowIdentifierViewer';
import useReturnTo from '@/hooks/useReturnTo';
import RequestScopes from '@/pages/profile/apps/application/components/RequestScopes';

import TermsOfUseLabel from '../TermsOfUseLabel';
import {
  Purpose,
  SELECT_OPTION_ERROR,
  type ServiceNowRequestFormModalProps,
  SubscriberSubType,
  SubscriberType,
} from './constants';

export default function ServiceNowApiAccessFormModal({
  onClose,
  entitlement,
}: ServiceNowRequestFormModalProps): JSX.Element {
  const { id: appId } = useParams();
  const navigate = useNavigate();
  const [, setReturnTo] = useReturnTo();

  const existingScopeIds = entitlement?.scopeEntitlements
    .filter(({ liveMode }) => !liveMode)
    .map(({ scopeId }) => scopeId);

  const scopes = entitlement.api.scopes?.map((scope) => ({
    id: scope.id,
    name: scope.name,
    description: scope.description,
    selected: existingScopeIds?.includes(scope.id),
  }));

  const {
    controller,
    controller: { setValue, values, setErrors, setFormError },
    handleSubmit,
    register,
    submitting,
  } = useEufemiaForm(createApiAccessTicketVariablesInputRequestSchema, {
    apiId: entitlement.api.id,
    scopeIds: scopes?.filter(({ selected }) => selected).map(({ id }) => id),
    termsOfUseAccepted: entitlement.api.termsOfUse ? false : undefined,
    purpose: entitlement.api.piiExposed ? Purpose.Consent : undefined,
  });

  const onScopeChange = (scopeId: string, checked: boolean) => {
    if (checked) {
      setValue('scopeIds', [...(controller.values.scopeIds || []), scopeId]);
      controller.triggerValidation('scopeIds');
    } else {
      setValue(
        'scopeIds',
        controller.values?.scopeIds?.filter((id) => scopeId !== id),
      );
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    if (appId) {
      const isCheckboxGroupValid =
        entitlement.api.severityClassification ===
          SeverityClassification.STRICTLY_CONFIDENTIAL &&
        !anyTrue(
          data.dataWillBeEncryptedInTransit,
          data.dataWillBeEncryptedOnFieldLevel,
          data.dataWillBeEncryptedOnTableLevel,
          data.onlyPersonsWithSpecificRoles,
        );

      if (isCheckboxGroupValid) {
        return setErrors({
          dataWillBeEncryptedInTransit: SELECT_OPTION_ERROR,
          dataWillBeEncryptedOnFieldLevel: SELECT_OPTION_ERROR,
          dataWillBeEncryptedOnTableLevel: SELECT_OPTION_ERROR,
          onlyPersonsWithSpecificRoles: SELECT_OPTION_ERROR,
        });
      }

      try {
        await requestLiveModeV2(appId, { ...data });
        setReturnTo(window.location.pathname);
        navigate(
          '/success?title=Access request sent!&subtitle=Your access request has been sent! Sit back and relax while it is being handled.',
        );
      } catch (error) {
        if (ApiError.isApiError(error)) {
          setFormError(error.message);
        } else {
          throw error;
        }
      }
    }
  });
  useEffect(() => {
    if (values.subscriberType === 'data' && !values.subscriberSubType) {
      // provide default value to field if data is selected
      setValue('subscriberSubType', 'events');
    } else if (values.subscriberType !== 'data') {
      setValue('subscriberSubType', undefined);
    }
  }, [setValue, values.subscriberSubType, values.subscriberType]);

  useEffect(() => {
    const isValid = anyTrue(
      !!values.dataWillBeEncryptedInTransit,
      !!values.dataWillBeEncryptedOnFieldLevel,
      !!values.dataWillBeEncryptedOnTableLevel,
      !!values.onlyPersonsWithSpecificRoles,
    );

    if (isValid) {
      setErrors({});
    }
  }, [
    setErrors,
    values.dataWillBeEncryptedInTransit,
    values.dataWillBeEncryptedOnFieldLevel,
    values.dataWillBeEncryptedOnTableLevel,
    values.onlyPersonsWithSpecificRoles,
  ]);

  return (
    <ModalPage
      actualModal
      onClose={onClose}
      skeleton={submitting}
      subtitle={`You are now in the process of requesting access to ${
        entitlement.api.approvalStage === 'test'
          ? 'both the test and live environments'
          : 'the live environment'
      }`}
      title={
        entitlement.api.approvalStage === 'test'
          ? 'Test and Live environments request'
          : 'Live environment request'
      }
    >
      {Object.values(controller.errors).some(
        (value) => value !== undefined,
      ) && <pre>{JSON.stringify(controller.errors, null, 2)}</pre>}
      <FormSet direction="vertical" on_submit={onSubmit}>
        <FormRow>
          <RequestScopes
            apiDescription={entitlement.api.description}
            apiName={entitlement.api.name}
            onChange={onScopeChange}
            scopes={scopes ?? []}
          />
        </FormRow>
        {controller.getError('scopeIds') && (
          <FormRow top="x-small">
            <FormStatus text={controller.getError('scopeIds')} />
          </FormRow>
        )}

        <H2 top="large">Additional information</H2>
        <Card stack>
          <Div style={{ width: '800px' }}>
            <FormRow top="small">
              <Input
                label={
                  <>
                    ServiceNow Identifier (BA/BSN)
                    <HelpButton
                      left="small"
                      size="small"
                      title="ServiceNow Identifier (BA/BSN)"
                    >
                      The ID to identify consuming business application
                      (BAXXXXX) or service offering (BSNXXXXXXX) in ServiceNow.
                    </HelpButton>
                  </>
                }
                label_direction="vertical"
                placeholder="BAXXXXX / BSNXXXXXXX"
                size={40}
                stretch
                {...register.input('serviceNowIdentifier')}
              />
            </FormRow>
            <FormRow top="medium">
              <ServiceNowIdentifierViewer
                onChange={(value) =>
                  setValue('serviceNowIdentifierSysId', value)
                }
                serviceNowIdentifier={controller.values.serviceNowIdentifier}
              />
            </FormRow>

            <Divider />
            <>
              <FormRow top="medium">
                <Dropdown
                  data={SubscriberType}
                  label="Subscriber type"
                  label_direction="vertical"
                  stretch
                  title="Please select a value"
                  {...register.dropdown('subscriberType')}
                />
              </FormRow>

              {values.subscriberType === 'data' && (
                <FormRow top="medium">
                  <Dropdown
                    data={SubscriberSubType}
                    label="Subscriber sub type"
                    label_direction="vertical"
                    stretch
                    title="Please select a value"
                    {...register.dropdown('subscriberSubType')}
                  />
                </FormRow>
              )}
            </>
            <Divider />
            {entitlement.api.severityClassification ===
              SeverityClassification.STRICTLY_CONFIDENTIAL && (
              <FormRow top="medium">
                <FormLabel>
                  This API is exposing personal identifiable information (PII),
                  please specify how it should be handled.
                </FormLabel>
                <Checkbox
                  label="Only persons with specific roles"
                  top="small"
                  {...register.checkbox('onlyPersonsWithSpecificRoles')}
                />
                <Checkbox
                  label="Data will be encrypted on field level"
                  top="small"
                  {...register.checkbox('dataWillBeEncryptedOnFieldLevel')}
                />
                <Checkbox
                  label="Data will be encrypted on table level"
                  top="small"
                  {...register.checkbox('dataWillBeEncryptedOnTableLevel')}
                />
                <Checkbox
                  label="Data will be encrypted in transit"
                  top="small"
                  {...register.checkbox('dataWillBeEncryptedInTransit')}
                />
              </FormRow>
            )}
            {entitlement.api.piiExposed && (
              <FormRow top="medium">
                <Dropdown
                  data={Purpose}
                  label="Purpose for receiving PII data"
                  label_direction="vertical"
                  stretch
                  title="Please select a value"
                  {...register.dropdown('purpose')}
                />
              </FormRow>
            )}

            <Divider />
            <>
              <FormRow top="medium">
                <Textarea
                  label="Use case"
                  label_direction="vertical"
                  placeholder="Please provide some information of how you are going to use the API"
                  rows={5}
                  stretch
                  {...register.textarea('subscriberDescription')}
                />
              </FormRow>

              {entitlement.api.termsOfUse && (
                <FormRow top="medium">
                  <Checkbox
                    label={
                      <TermsOfUseLabel
                        apiName={entitlement.api.name}
                        termsOfUse={entitlement.api.termsOfUse}
                        termsOfuseUpdatedAt={
                          entitlement.api.termsOfUseUpdatedAt
                        }
                      />
                    }
                    {...register.checkbox('termsOfUseAccepted')}
                  />
                </FormRow>
              )}
            </>
          </Div>
        </Card>
        <FormStatus bottom="large" text={controller.formError} top="medium" />
        <FormRow top="small">
          <Button disabled={submitting} type="submit">
            Request access
          </Button>
        </FormRow>
      </FormSet>
    </ModalPage>
  );
}
