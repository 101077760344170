export const headerMap: Record<string, string> = {
  crq: 'DNBAPI-CRQ-Number',
};
export interface HeadersInput {
  crq?: string;
}

export function getHeaders(input: HeadersInput) {
  const headers: Record<string, string> = {};
  for (const key in headerMap) {
    const typedKey = key as keyof HeadersInput;
    if (input[typedKey]) {
      headers[headerMap[key]] = input[typedKey]!;
    }
  }
  return new Headers(headers);
}
