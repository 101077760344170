import { Space, Tabs } from '@dnb/eufemia';
import type { Dereferenced } from '@portals/shared/common/open-api/types';
import { hasOwnProperty } from '@portals/shared-frontend/utils';
import type { OpenAPIV3 } from 'openapi-types';
import { useEffect, useState } from 'react';

import type {
  DereferencedSchemaObject,
  MenuItemType,
  TabsType,
} from '@/pages/api-documentation/constants/types';
import MediaTypeContent from '@/pages/api-documentation/sections/reference/components/ReferenceView/components/Item/components/MediaTypeContent';
import SchemaItem from '@/pages/api-documentation/sections/reference/components/ReferenceView/components/Item/components/SchemaItem';

import * as Styled from './index.styles';
import {
  H3S,
  H4S,
} from '@/pages/api-documentation/styles/Api.Reference.styles';

type ResponsesProps = {
  data: OpenAPIV3.ResponsesObject;
};

const Responses = ({ data }: ResponsesProps) => {
  const [tabs, setTabs] = useState<TabsType>({ active: '', items: [] });

  useEffect(() => {
    if (tabs.items.length === 0 && data) {
      const newTabs: MenuItemType[] = [];

      Object.keys(data).map((rep) => {
        newTabs.push({ title: rep, key: rep });
      });

      setTabs({ active: newTabs[0].key, items: newTabs });
    }
  }, [data, tabs]);

  const { description, content } =
    (tabs.active &&
      (data[tabs.active] as Dereferenced<OpenAPIV3.ParameterBaseObject>)) ||
    {};

  const { headers } =
    (tabs.active && (data[tabs.active] as OpenAPIV3.ResponseObject)) || {};

  return (
    <div>
      <H3S>Responses</H3S>
      <Tabs
        content_spacing={false}
        data={tabs.items}
        no_border
        on_change={({ key }) => setTabs({ active: key, items: tabs.items })}
        selected_key={tabs.active}
      />

      {tabs.active && (
        <Space top="small">
          {description}
          {headers && (
            <div>
              <H4S>Response headers</H4S>
              <Space bottom="small" top="small">
                <Styled.ResponseHeaderList>
                  {Object.keys(headers).map((headerItem, index) => {
                    const headerSchema = headers[headerItem];
                    const schemaItemDescription = hasOwnProperty(
                      headerSchema,
                      'description',
                    )
                      ? headerSchema.description
                      : '';

                    if (hasOwnProperty(headerSchema, 'schema')) {
                      const schemaHeaderItem = {
                        ...(headerSchema.schema as OpenAPIV3.HeaderObject),
                        description: schemaItemDescription,
                      } as OpenAPIV3.SchemaObject;

                      return (
                        <SchemaItem
                          isSubSchema
                          key={index}
                          name={headerItem}
                          schema={schemaHeaderItem as DereferencedSchemaObject}
                          schemaType="response"
                        />
                      );
                    }

                    return null;
                  })}
                </Styled.ResponseHeaderList>
              </Space>
            </div>
          )}
          {content && Object.keys(content).length > 0 && (
            <MediaTypeContent content={content} schemaType="response" />
          )}
        </Space>
      )}
    </div>
  );
};

export default Responses;
