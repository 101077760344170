import createClient, { Middleware } from 'openapi-fetch';
import { createQueryHook } from 'swr-openapi';

import { getAzureToken, isSignedInWithAzure } from '@/auth/azure';
import { getCognitoIdToken, isLoggedInWithCognito } from '@/auth/cognito';
import type { paths } from '@/openapi/schema';

const backendClient = createClient<paths>({
  baseUrl: '/service/v2',
});

const authMiddleware: Middleware = {
  onRequest: async ({ request }) => {
    if (await isSignedInWithAzure()) {
      const token = await getAzureToken();
      if (token) {
        request.headers.set('Authorization', `Bearer ${token}`);
      }
    } else if (await isLoggedInWithCognito()) {
      const token = await getCognitoIdToken();
      if (token) {
        request.headers.set('Authorization', `Bearer ${token}`);
      }
    }
  },
};

backendClient.use(authMiddleware);

export const useBackend = createQueryHook(backendClient, 'v2');
