import type { FeatureFlagDto } from '@portals/shared/portal/FeatureFlagDto';
import { useContext } from 'react';

import FeatureFlagContext from '@/hooks/FeatureFlagContext';

interface UseFeatureFlags {
  featureFlags: FeatureFlagDto;
  isLoading: boolean;
}

export default function useFeatureFlags(): UseFeatureFlags {
  const { featureFlags, isLoading } = useContext(FeatureFlagContext);

  return {
    featureFlags: featureFlags,
    isLoading: isLoading,
  };
}
