import {
  Button,
  Checkbox,
  FormRow,
  FormSet,
  FormStatus,
  Input,
  Link,
  P,
  Ul,
} from '@dnb/eufemia';
import { Field } from '@dnb/eufemia/extensions/forms';
import { log_in_medium as LogInMediumIcon } from '@dnb/eufemia/icons';
import { PASSWORD_MATCHER } from '@portals/shared/common/matchers';
import {
  ATLEAST_N,
  INVALID_PASSWORD,
  TERMS_AGREEMENT,
} from '@portals/shared/common/messages';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { type JSX, useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { z } from 'zod';

import { createUser } from '@/api/user';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import ModalPage from '@/components/ModalPage';

import VerifyEmail from './VerifyEmail';

const signUpFormSchema = z.object({
  firstName: z.string().min(1, ATLEAST_N(1)),
  lastName: z.string().min(1, ATLEAST_N(1)),
  email: z.string().email(),
  password: z
    .string()
    .min(8, INVALID_PASSWORD)
    .regex(PASSWORD_MATCHER, INVALID_PASSWORD),
  acceptedTos: z.boolean().refine((accepted) => accepted, TERMS_AGREEMENT),
});

type SignUpFormSchema = z.infer<typeof signUpFormSchema>;

export default function SignUp(): JSX.Element {
  const [ssoSignUp, setSsoSignUp] = useState<boolean>(false);
  const [submittedEmail, setSubmittedEmail] = useState<string>();
  const {
    controller: { formError, values, setValue, errors },
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(signUpFormSchema, { acceptedTos: false });

  const onSubmit = useCallback(
    async (data: SignUpFormSchema) => {
      await createUser(data);
      setSubmittedEmail(data.email);
    },
    [setSubmittedEmail],
  );

  const handleFormSubmit = handleSubmit(onSubmit);

  useEffect(() => {
    if (values.email?.endsWith('@dnb.no')) {
      setSsoSignUp(true);
      return;
    }
    setSsoSignUp(false);
  }, [values.email]);

  if (submittedEmail) {
    return <VerifyEmail email={submittedEmail} />;
  }

  return (
    <ModalPage subtitle="Sign up" title="Please fill out the form">
      {submitting && <LoadingModal />}
      <Container size="small">
        {ssoSignUp ? (
          <FormSet vertical>
            <FormRow>
              <Input
                label="Email address"
                placeholder="email@example.com"
                size="large"
                stretch
                {...register.input('email')}
              />
              <FormStatus
                state="info"
                text="Because you have a DNB account, you can log in directly with DNB single sign on."
                top="x-small"
              />
            </FormRow>
            <FormRow top="medium">
              <Button
                disabled={submitting}
                element={RouterLink}
                icon={LogInMediumIcon}
                icon_position="left"
                icon_size="medium"
                size="large"
                to="/sso"
              >
                Log in with DNB account
              </Button>
            </FormRow>
          </FormSet>
        ) : (
          <>
            <FormSet on_submit={handleFormSubmit} vertical>
              <FormRow>
                <Input
                  autoFocus
                  label="First name"
                  placeholder="John"
                  size="large"
                  stretch
                  {...register.input('firstName')}
                />
              </FormRow>
              <FormRow top="medium">
                <Input
                  label="Last name"
                  placeholder="Smith"
                  size="large"
                  stretch
                  {...register.input('lastName')}
                />
              </FormRow>
              <FormRow top="medium">
                <Input
                  label="Email address"
                  placeholder="example@mail.com"
                  size="large"
                  stretch
                  {...register.input('email')}
                />
              </FormRow>
              <FormRow top="medium">
                <Field.Password
                  error={
                    errors['password']
                      ? new Error(errors['password'])
                      : undefined
                  }
                  help={{
                    outset: false,
                    content: (
                      <>
                        Password must contain:
                        <Ul>
                          <li>At least 8 characters</li>
                          <li>Upper and lowercase letters</li>
                          <li>At least one number</li>
                          <li>At least one special character (!#$%&*?@^-)</li>
                        </Ul>
                      </>
                    ),
                  }}
                  label="Password"
                  onChange={(value) => setValue('password', value)}
                  required
                  size="large"
                  width="stretch"
                />
              </FormRow>
              <FormRow top="medium">
                <Checkbox
                  label={
                    <>
                      I agree to the{' '}
                      <Link href="/terms-of-use" target="_blank">
                        terms of use
                      </Link>
                    </>
                  }
                  {...register.checkbox('acceptedTos')}
                />
              </FormRow>
              <FormRow top="large">
                <Button
                  disabled={submitting}
                  icon_position="left"
                  icon_size="medium"
                  size="large"
                  status={formError}
                  type="submit"
                >
                  Sign up
                </Button>
              </FormRow>
            </FormSet>
            <P top="medium">
              <>
                Already have an account?{' '}
                <Link element={RouterLink} to="/login">
                  Log in
                </Link>
              </>
            </P>
          </>
        )}
      </Container>
    </ModalPage>
  );
}
