import { Card, P, Space } from '@dnb/eufemia';
import { ApiWithStatusAndScopesDto } from '@portals/shared/portal/ApiDto';

import ScopeCard from '@/pages/profile/apps/application/components/ScopeCard';

interface Props {
  api: ApiWithStatusAndScopesDto;
}

export default function ScopesSection({ api }: Props) {
  return (
    <Card direction="vertical">
      {api.scopes && api.scopes.length > 0 ? (
        api.scopes?.map((scope, index) => (
          <Space key={scope.id} stretch top={index === 0 ? 0 : 'small'}>
            <ScopeCard
              environments={[]}
              scopeEntitlement={{
                scopeId: scope.id,
                scopeName: scope.name,
                scopeDescription: scope.description,
                liveMode: false,
              }}
              showAccessSection={false}
            />
          </Space>
        ))
      ) : (
        <P>Scopes have not been created.</P>
      )}
    </Card>
  );
}
