import { Button, Flex, GlobalError, Img, Space } from '@dnb/eufemia';
import type { JSX } from 'react';

import Container from '@/components/Container';

import WomanSearchingLight from '@/illustrations/WomanSearchingLight.svg?url';

interface Props {
  status: string;
}

export default function GlobalErrorPage({ status }: Props): JSX.Element {
  return (
    <Container centered size="small" top="x-large">
      <Flex.Stack align="center">
        <GlobalError code={null} status={status} />
        <Img
          alt="A woman searching in a box"
          src={WomanSearchingLight}
          width={320}
        />
        <Space>
          <Button href="/" size="large" top="large">
            Go to landing page
          </Button>
        </Space>
      </Flex.Stack>
    </Container>
  );
}
