import { Card, P, Space } from '@dnb/eufemia';
import { ApiWithStatusAndScopesDto } from '@portals/shared/portal/ApiDto';

import ScopeRequestCard from '@/pages/profile/apps/application/components/ScopeRequestCard';

interface Props {
  api: ApiWithStatusAndScopesDto;
}

export default function ScopeRequestSection({ api }: Props) {
  return (
    <Card direction="vertical">
      {api.scopeRequests && api.scopeRequests.length > 0 ? (
        api.scopeRequests.map((request, index) => (
          <Space key={request.id} stretch top={index === 0 ? 0 : 'small'}>
            <ScopeRequestCard scopeRequest={request} />
          </Space>
        ))
      ) : (
        <P>Scopes have not been requested.</P>
      )}
    </Card>
  );
}
