/* eslint-disable max-lines */
import { Button, H4, Link, Space, Tooltip } from '@dnb/eufemia';
import { type AppWithEntitlementsDto } from '@portals/shared/portal/AppDto';
import type {
  CiamClientDto,
  ClientDto,
} from '@portals/shared/portal/ClientDto';
import { FeatureFlagDto } from '@portals/shared/portal/FeatureFlagDto';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { deleteClientFromPortal } from '@/api/app';
import Card from '@/components/Card';
import Divider from '@/components/Divider';
import NoData from '@/components/NoData';
import useAuth from '@/hooks/useAuth';

import Client from '../../Client';

import style from '../index.module.css';

const privilegeGroup =
  ENVIRONMENT_NAME === 'prod'
    ? {
        displayName:
          'BSN0005357 DNB Developer Portal Client MGMT Eligible Operator Access in Production',
        id: 'App_BSN0005357_DNBDeveloperPortal_Client_Mgmt_Eligible_prod_opr',
      }
    : {
        displayName:
          'BSN0005357 DNB Developer Operator Access to Client MGMT Eligible Dev',
        id: 'App_BSN0005357_DNB_Developer_Client_Mgmt_Eligible_dev_operator',
      };

const getTipMessage = (
  isAppAdmin: boolean,
  hasClientManagementGrp: boolean,
  appHasEntitlements: boolean,
) => {
  if (!isAppAdmin) {
    return (
      <Tooltip size="large" style={{ maxWidth: '300px' }}>
        Only team admin&lsquo;s have access to create clients for applications.
      </Tooltip>
    );
  }

  if (!appHasEntitlements) {
    return (
      <Tooltip size="large" style={{ maxWidth: '300px' }}>
        This app has no entitlements to any integration services, which is
        required to create clients. Please review our documentation on how to
        consume integration services{' '}
        <Link
          href="https://developer.dnb.no/getting-started/dnb-internal-consumers"
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </Link>{' '}
        , and provide integration services{' '}
        <Link
          href="https://developer.dnb.no/getting-started/api-providers"
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </Link>{' '}
        .
      </Tooltip>
    );
  }

  if (!hasClientManagementGrp) {
    return (
      <Tooltip size="large" style={{ maxWidth: '300px' }}>
        In order to create clients you need to request access to the role &quot;
        {privilegeGroup.displayName}&quot;{' '}
        <Link
          href="https://dnbprod.service-now.com/resolve?id=sc_cat_item_guide&sys_id=549b0f921bb3949089ab542a2d4bcb09"
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </Link>
      </Tooltip>
    );
  }

  return '';
};

interface Props {
  app: AppWithEntitlementsDto;
  isAdmin: boolean;
  onClientDeleted: (clientId: string) => void;
  featureFlags: FeatureFlagDto;
}

export function ClientCard({
  app,
  isAdmin,
  onClientDeleted,
  featureFlags,
}: Props) {
  const { roles } = useAuth();
  const navigate = useNavigate();
  const {
    data: ciamClients,
    isValidating: ciamClientsLoading,
    mutate: mutateCiamClients,
  } = useSWR<CiamClientDto[]>(`/apps/${app.id}/ciam-clients`, {
    revalidateOnMount: true,
  });

  const onDeleteClientFromPortal = useCallback(
    async (client: ClientDto) => {
      if (app) {
        await deleteClientFromPortal(app.id, client.id);
        onClientDeleted(client.id);
      }
    },
    [app, onClientDeleted],
  );

  const liveClients = app.clients.filter(({ liveMode }) => liveMode);
  const testClients = app.clients.filter(({ liveMode }) => !liveMode);

  const hasClientManagementGrp = useMemo(() => {
    return roles.includes(privilegeGroup.id);
  }, [roles]);

  const hasScopeEntitlements = app.currentApiAndScopeEntitlements.some(
    ({ scopeEntitlements }) => scopeEntitlements.length > 0,
  );

  const tipMessage = useMemo(
    () => getTipMessage(isAdmin, hasClientManagementGrp, hasScopeEntitlements),
    [isAdmin, hasClientManagementGrp, hasScopeEntitlements],
  );

  return (
    <Card>
      {app.clients.length > 0 ? (
        <Space
          bottom="small"
          className={style['Credential-Container']}
          top="small"
        >
          <H4>Live Clients</H4>
          <Space className={style['Credential-Card']}>
            {liveClients.map((client) => (
              <Client
                ciamClient={ciamClients?.find((c) => c.id === client.id)}
                ciamClientsLoading={ciamClientsLoading}
                client={client}
                currentApiAndScopeEntitlements={
                  app.currentApiAndScopeEntitlements
                }
                hasPrivilageToEditClient={hasClientManagementGrp}
                isAdmin={isAdmin}
                key={client.id}
                noPrivilageError={tipMessage}
                onClientUpdated={() => mutateCiamClients()}
                onDeleteClicked={() => onClientDeleted(client.id)}
                onDeleteFromPortalClicked={
                  featureFlags.ENABLE_CLIENT_IMPORT
                    ? () => onDeleteClientFromPortal(client)
                    : null
                }
                project={app.project}
              />
            ))}
          </Space>
          {liveClients.length === 0 && (
            <NoData message="No live clients have been created for this app." />
          )}
          <H4 bottom="0" top="medium">
            Test Clients
          </H4>

          <Space className={style['Credential-Card']}>
            {testClients.map((client) => (
              <Client
                ciamClient={ciamClients?.find((c) => c.id === client.id)}
                ciamClientsLoading={ciamClientsLoading}
                client={client}
                currentApiAndScopeEntitlements={
                  app.currentApiAndScopeEntitlements
                }
                hasPrivilageToEditClient={hasClientManagementGrp}
                isAdmin={isAdmin}
                key={client.id}
                noPrivilageError={tipMessage}
                onClientUpdated={() => mutateCiamClients()}
                onDeleteClicked={() => onClientDeleted(client.id)}
                onDeleteFromPortalClicked={
                  featureFlags.ENABLE_CLIENT_IMPORT
                    ? () => onDeleteClientFromPortal(client)
                    : null
                }
                project={app.project}
              />
            ))}
          </Space>
          {testClients.length === 0 && (
            <NoData message="No test clients have been created for this app." />
          )}
        </Space>
      ) : (
        <Space
          bottom="small"
          className={style['Credential-Container']}
          top="small"
        >
          <NoData message="No clients have been created for this app." />
        </Space>
      )}
      <Divider />
      <Space top="small">
        <Button
          disabled={
            !isAdmin || !hasClientManagementGrp || !hasScopeEntitlements
          }
          icon="add"
          icon_position="left"
          on_click={() =>
            navigate(
              app.owner.type === 'team'
                ? `/team/${app.owner.id}/application/${app.id}/create-client`
                : `/application/${app.id}/create-client`,
            )
          }
          tooltip={tipMessage}
        >
          New Client
        </Button>
        {featureFlags.ENABLE_CLIENT_IMPORT && (
          <Button
            icon="download"
            icon_position="left"
            left="small"
            on_click={() =>
              navigate(
                app.owner.type === 'team'
                  ? `/team/${app.owner.id}/application/${app.id}/import-client`
                  : `/application/${app.id}/import-client`,
              )
            }
          >
            Import Client
          </Button>
        )}
      </Space>
    </Card>
  );
}
