import {
  Button,
  Checkbox,
  Dialog,
  FormRow,
  FormSet,
  H2,
  P,
} from '@dnb/eufemia';
import type { ApiDto } from '@portals/shared/portal/ApiDto';
import type {
  SubscriberDto,
  UpdateSubscriptionsInputDto,
} from '@portals/shared/portal/SubscriptionDto';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import ModalPage from '@/components/ModalPage';

import style from './ManageSubscriptions.module.css';

type DerivedApi = Pick<ApiDto, 'id' | 'name' | 'enableStatus'>;

import { trash_medium as TrashMediumIcon } from '@dnb/eufemia/icons';
import { useAsync, useEufemiaForm } from '@portals/shared-frontend/hooks';
import { keyByAndMap } from '@portals/shared-frontend/utils';
import { type JSX, useCallback, useEffect, useMemo, useState } from 'react';
import { z } from 'zod';

import {
  createSubscriptions,
  deleteSubscriptions,
  updateSubscriptions,
} from '@/api/subscriptions';
import AuthTemplate from '@/components/AuthTemplate';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import { createDummyApi } from '@/dummyData';
import { useUser } from '@/hooks/useUser';

const DUMMY_APIS = new Array(10).fill(0).map((_, i) => createDummyApi(i));

const subscriptionsFormSchema = z.object({
  posts: z.boolean(),
  status: z.record(z.string(), z.boolean()),
  releases: z.record(z.string(), z.boolean()),
});

export default function ManageSubscriptions(): JSX.Element {
  const { id: token } = useParams();
  const navigate = useNavigate();
  const { data: apisData, isValidating: apisLoading } =
    useSWR<ApiDto[]>('/api');
  const {
    data: subscriptions,
    isValidating: subscriptionsLoading,
    mutate,
  } = useSWR<SubscriberDto>(
    token ? `/subscriptions/${token}` : '/subscriptions',
  );

  const apis = apisData || DUMMY_APIS;
  const hasLoaded = !apisLoading && !subscriptionsLoading;

  const {
    register,
    handleSubmit,
    submitting,
    controller: { setValue, getValue, setValues },
  } = useEufemiaForm(subscriptionsFormSchema, {
    posts: false,
    status: {},
    releases: {},
  });

  const { user } = useUser();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDeleteAll, setOpenDeleteAll] = useState(false);

  const derivedApis = useMemo(() => {
    const ret: Record<string, DerivedApi> = {};

    subscriptions?.apis.forEach((api) => {
      ret[api.apiId] = {
        id: api.apiId,
        name: api.apiName,
        enableStatus: api.status,
      };
    });

    apis?.forEach(({ id, name, enableStatus }) => {
      ret[id] = { id, name, enableStatus };
    });

    return Object.values(ret).sort((a, b) => {
      return a.name < b.name ? -1 : 1;
    });
  }, [apis, subscriptions]);

  const onSubmit = handleSubmit(
    useCallback(
      async ({ posts, status, releases }) => {
        const apis: UpdateSubscriptionsInputDto['apis'] = [];
        const apiIds = derivedApis.map(({ id }) => id);
        for (const id of apiIds) {
          if (status[id] != null || releases[id] != null) {
            apis.push({
              apiId: id,
              status: !!status[id],
              releases: !!releases[id],
            });
          }
        }
        if ((token || user) && subscriptions) {
          await updateSubscriptions(
            {
              posts,
              apis,
            },
            token,
          );
          setOpenConfirm(true);
        } else if (user && !subscriptions) {
          await createSubscriptions({
            email: user?.email,
            status: apiIds.filter((id) => status[id]),
            releases: apiIds.filter((id) => releases[id]),
            posts,
          });
          setOpenConfirm(true);
        } else {
          return navigate('/404'); //TODO: add <GlobalError status="404" />;
        }
      },
      [token, subscriptions, user, navigate, derivedApis],
    ),
  );

  const hasToken = token ? '/' : '/profile/account';

  const onDelete = useAsync(async () => {
    await deleteSubscriptions(token);
    mutate();
    navigate(hasToken);
  }, [token, mutate, navigate, hasToken]);

  const toggleAll = (checkboxGroup: 'status' | 'releases') => () => {
    const filteredApis = derivedApis.filter(
      ({ enableStatus }) => enableStatus || checkboxGroup === 'releases',
    );
    const shouldToggleOn = filteredApis
      .map(({ id }) => getValue(`${checkboxGroup}.${id}`))
      .some((on) => !on);

    for (const { id } of filteredApis) {
      setValue(`${checkboxGroup}.${id}`, shouldToggleOn);
    }
  };

  useEffect(() => {
    if (subscriptions) {
      const status = keyByAndMap(subscriptions.apis, 'apiId', 'status');
      const releases = keyByAndMap(subscriptions.apis, 'apiId', 'releases');

      setValues({
        posts: subscriptions?.posts,
        status,
        releases,
      });
    }
  }, [derivedApis, setValues, subscriptions]);

  return (
    <AuthTemplate>
      <ModalPage
        description="Manage your existing subscriptions or subscribe to more updates."
        onClose={() => navigate(hasToken)}
        skeleton={!hasLoaded}
        subtitle="Subscriptions"
        title="Manage"
      >
        {submitting && <LoadingModal />}
        <Container size="large">
          <FormSet direction="vertical" on_submit={onSubmit}>
            <FormRow>
              <H2>News</H2>
              <Checkbox
                label="Newsletters"
                top="small"
                {...register.checkbox('posts')}
              />
            </FormRow>
            <FormRow top="medium">
              <H2>Status</H2>
              <Button
                className={style['ToggleAllButton']}
                on_click={toggleAll('status')}
                size="small"
                top="small"
                variant="tertiary"
              >
                Toggle all
              </Button>
              {derivedApis
                .filter((api) => api.enableStatus)
                .map(({ id, name }) => (
                  <Checkbox
                    key={id}
                    label={name}
                    top="small"
                    {...register.checkbox(`status.${id}`)}
                  />
                ))}
            </FormRow>
            <FormRow top="medium">
              <H2>Releases</H2>
              <Button
                className={style['ToggleAllButton']}
                on_click={toggleAll('releases')}
                size="small"
                top="small"
                variant="tertiary"
              >
                Toggle all
              </Button>
              {derivedApis.map(({ id, name }) => (
                <Checkbox
                  key={id}
                  label={name}
                  top="small"
                  {...register.checkbox(`releases.${id}`)}
                />
              ))}
            </FormRow>
            <FormRow direction="horizontal" top="large">
              <Button
                disabled={submitting}
                right="small"
                size="large"
                type="submit"
              >
                Confirm
              </Button>

              {!!subscriptions && (
                <Button
                  disabled={submitting}
                  onClick={() => setOpenDeleteAll(true)}
                  size="large"
                  top="small"
                  variant="secondary"
                >
                  Delete all
                </Button>
              )}
            </FormRow>
          </FormSet>
        </Container>
        <Dialog
          confirmText="Got it!"
          description={<P>Your subscriptions were updated.</P>}
          hideDecline
          onConfirm={() => navigate(hasToken)}
          openState={openConfirm}
          title="Success!"
          variant="confirmation"
        />
        <Dialog
          confirmText="Delete"
          confirmType="warning"
          declineText="Cancel"
          description={<P>This action can&apos;t be undone.</P>}
          icon={TrashMediumIcon}
          onClose={() => {
            setOpenDeleteAll(false);
          }}
          onConfirm={onDelete.execute}
          openState={openDeleteAll}
          title="Are you sure you want to delete all subscriptions?"
          variant="confirmation"
        />
      </ModalPage>
    </AuthTemplate>
  );
}
