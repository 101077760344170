import { Dashboard } from '@portals/shared-frontend/components';
import useSWR from 'swr';

interface Props {
  appId: string;
  apiId: string;
}

export default function DashboardSection({ appId, apiId }: Props) {
  const { data: dashboard } = useSWR(`/dashboard/apps/${appId}/apis/${apiId}`);

  return <Dashboard data={dashboard ?? []} />;
}
