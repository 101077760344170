import { Div } from '@dnb/eufemia';
import { ApiType } from '@portals/shared/portal/ApiDto';
import classNames from 'classnames';

import style from './index.module.css';

interface Props {
  type: ApiType;
}

export default function ApiTypeBadge({ type }: Props) {
  const badgeValue = type === 'REST' ? 'API' : 'Event';

  return (
    <Div className={classNames(style['badge'], style[type.toLowerCase()])}>
      {badgeValue}
    </Div>
  );
}
