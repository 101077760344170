import { Icon } from '@dnb/eufemia';
import {
  chevron_down_medium as ChevronDownIcon,
  chevron_up_medium as ChevronUpIcon,
} from '@dnb/eufemia/icons';
import Markdown from '@portals/shared-frontend/components/Markdown';
import type { OpenAPIV3 } from 'openapi-types';
import { useMemo, useState } from 'react';

import type { DereferencedParameterObject } from '@/pages/api-documentation/constants/types';
import { mergeParameters } from '@/pages/api-documentation/utils/transformations';

import ApiUrl from './components/ApiUrl';
import Authentication from './components/Authentication';
import Parameters from './components/Parameters';
import RequestBody from './components/RequestBody';
import Responses from './components/Responses';

import { Header, ItemWrapper } from './index.styles';
import { H3S } from '@/pages/api-documentation/styles/Api.Reference.styles';
import {
  Action,
  Deprecated,
} from '@/pages/api-documentation/styles/Api.styles';

type ItemProps = {
  path: string;
  action: string;
  data: OpenAPIV3.OperationObject;
  parameters:
    | (OpenAPIV3.ReferenceObject | OpenAPIV3.ParameterObject)[]
    | undefined;
  security: {
    security: OpenAPIV3.SecurityRequirementObject[] | undefined;
    itemSecurity?: OpenAPIV3.SecurityRequirementObject[];
    schemas:
      | {
          [p: string]:
            | OpenAPIV3.ReferenceObject
            | OpenAPIV3.SecuritySchemeObject;
        }
      | undefined;
  };
  servers: OpenAPIV3.ServerObject[] | undefined;
  compact: boolean;
};

const Item = ({
  path,
  action,
  data,
  security,
  servers,
  parameters,
  compact,
}: ItemProps) => {
  const {
    summary,
    parameters: itemParameters,
    security: itemSecurity,
    description,
    responses,
    tags,
    deprecated,
    requestBody,
  } = data;

  const [expanded, setExpanded] = useState(false);
  const key = `tags/${
    tags ? tags?.toString().replaceAll(' ', '-') + '/' : ''
  }operation/${action}${path}`
    .replaceAll('{', '')
    .replaceAll('}', '');

  const itemParams =
    itemParameters && parameters
      ? mergeParameters(
          itemParameters as DereferencedParameterObject[],
          parameters as DereferencedParameterObject[],
        )
      : itemParameters || parameters;

  return useMemo(() => {
    return (
      <ItemWrapper compact={compact} id={key}>
        <div className={`${compact ? 'compact' : ''}`}>
          <Header
            compact={compact}
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          >
            <Action small={false} type={action}>
              {action}
            </Action>
            <span>{compact ? path : summary}</span>
            {compact && (
              <Icon icon={expanded ? ChevronUpIcon : ChevronDownIcon} />
            )}
            {deprecated && <Deprecated>Deprecated</Deprecated>}
          </Header>
          <div className={`item-content ${expanded ? ' expanded' : ''}`}>
            {compact && <h3>{summary}</h3>}
            {description && <Markdown>{description}</Markdown>}
            <ApiUrl action={action} path={path} servers={servers} />
            {!!security && (
              <Authentication data={{ ...security, itemSecurity }} />
            )}
            {itemParams && (
              <>
                <H3S>Parameters</H3S>
                <Parameters data={itemParams as OpenAPIV3.ParameterObject[]} />
              </>
            )}

            {!!requestBody && (
              <RequestBody data={requestBody as OpenAPIV3.RequestBodyObject} />
            )}
            {!!responses && <Responses data={responses} />}
          </div>
        </div>
      </ItemWrapper>
    );
  }, [
    itemParams,
    responses,
    requestBody,
    compact,
    setExpanded,
    expanded,
    compact,
  ]);
};

export default Item;
