import { createContext } from 'react';

interface AuthContextValue {
  isSignedIn: boolean;
  isLoading: boolean;
  reload: () => void;
  roles: string[];
}

const AuthContext = createContext<AuthContextValue>({
  isSignedIn: false,
  isLoading: true,
  reload: () => {},
  roles: [],
});

export default AuthContext;
