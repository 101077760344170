import { css } from '@emotion/react';
import styled from '@emotion/styled';

type Props = {
  backgroundImage: string;
  isSmall: boolean;
};

export const HeroHeaderWrapper = styled.div<Props>`
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  object-fit: contain;
  background-size: cover;
  background-position: cover;
  overflow: hidden;
  height: ${(props) => (props.isSmall ? '15rem' : '12rem')};
  top: 0;
  ${(props) =>
    props.backgroundImage &&
    css`
      background-image: url(${props.backgroundImage});
    `};
  left: 0;
`;
