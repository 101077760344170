import { useContext } from 'react';

import AuthContext from '@/hooks/AuthContext';

// eslint-disable-next-line max-lines-per-function
export default function useAuth() {
  const { isSignedIn, isLoading, roles } = useContext(AuthContext);

  return {
    isSignedIn: isSignedIn,
    isLoading: isLoading,
    roles,
  };
}
