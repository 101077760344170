import HeroPage from '@/components/HeroPage';

import DeveloperPortalGuideContent from '../../../components/Guide/DeveloperPortalGuideContent';

import LandingPageBanner from '@/illustrations/LandingPageBanner.png?url';

export default function GettingStartedApiProviders() {
  return (
    <HeroPage
      heroIllustration={LandingPageBanner}
      noContainer
      noTopMargin
      subtitle="Follow these steps to publish your APIs"
      title="Getting started for API providers"
    >
      <DeveloperPortalGuideContent contentSlug="api-providers" />
    </HeroPage>
  );
}
