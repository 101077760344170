import { Flex } from '@dnb/eufemia';

import { EventResponse } from '@/openapi/types';
import EventCard from '@/pages/explorer/events/EventCard';

interface EventListProps {
  events: EventResponse[];
  onFavoriteChange: (eventId: string, isFavorite: boolean) => void;
}

export default function EventList({
  events,
  onFavoriteChange,
}: EventListProps) {
  return (
    <Flex.Stack space={{ bottom: 'large' }}>
      {events.map((event) => (
        <EventCard
          event={event}
          key={event.slug}
          onFavoriteChange={onFavoriteChange}
        />
      ))}
    </Flex.Stack>
  );
}
