import {
  Button,
  Checkbox,
  Dialog,
  FormRow,
  FormSet,
  FormStatus,
  GlobalError,
  Input,
  P,
} from '@dnb/eufemia';
import { email_medium as EmailMediumIcon } from '@dnb/eufemia/icons';
import type { ApiDto } from '@portals/shared/portal/ApiDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks/useEufemiaForm';
import { type JSX, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { z } from 'zod';

import { createSubscriptions } from '@/api/subscriptions';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import ModalPage from '@/components/ModalPage';
import { createDummyApi } from '@/dummyData';
import { useUser } from '@/hooks/useUser';

import style from './StatusSubscriptions.module.css';

const statusSubscriptionsFormSchema = z.object({
  email: z.string().email(),
  status: z
    .record(z.string(), z.boolean())
    .refine(
      (status) => Object.values(status).some(Boolean),
      'Select at least one API',
    ),
});

const DUMMY_APIS_WITH_STATUS: Array<ApiDto> = [
  createDummyApi(12345678),
  createDummyApi(15555934),
  createDummyApi(15983403),
  createDummyApi(18989890),
  createDummyApi(23498744),
];

export default function StatusSubscriptions(): JSX.Element {
  const { user } = useUser();
  const {
    submitting,
    register,
    handleSubmit,
    controller: { setValue, getValue, formError, getError },
  } = useEufemiaForm(statusSubscriptionsFormSchema, {
    status: {},
  });

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { data: apisData, isValidating: apisLoading } =
    useSWR<ApiDto[]>('/api');
  const apis = apisData || DUMMY_APIS_WITH_STATUS;
  const hasLoaded = !apisLoading && !!apis;
  const apisWithStatus = apis?.filter((api) => api.enableStatus);

  const toggleAll = () => {
    const shouldToggleOn = apisWithStatus
      ?.map(({ id }) => getValue(`status.${id}`))
      .some((on) => !on);

    if (apisWithStatus) {
      for (const { id } of apisWithStatus) {
        setValue(`status.${id}`, shouldToggleOn);
      }
    }
  };

  const onSubmit = handleSubmit(
    useCallback(
      async ({ email, status }) => {
        const apiIds = apis?.map(({ id }) => id);
        await createSubscriptions({
          email,
          status: apiIds?.filter((id) => status[id]),
        });
        setOpen(true);
      },
      [apis],
    ),
  );

  useEffect(() => {
    if (user?.email) {
      setValue('email', user.email);
    }
  }, [setValue, user?.email]);

  if (hasLoaded && !apis) {
    return <GlobalError status="404" />;
  }

  const formEmailValue = getValue('email') as string | undefined;

  return (
    <ModalPage
      onClose={() => navigate('/status')}
      skeleton={!hasLoaded}
      subtitle="Subscriptions"
      title="Status"
    >
      {submitting && <LoadingModal />}
      <FormSet on_submit={onSubmit} vertical>
        <Container size="small">
          <FormRow>
            <P>Please fill in your email below to get status updates.</P>
            <Input
              icon={EmailMediumIcon}
              placeholder="Email address"
              size="large"
              stretch
              top="medium"
              type="confirmation"
              {...register.input('email')}
            />
          </FormRow>
        </Container>
        <FormRow top="large">
          <P>
            Please select which APIs you would like to receive status updates
            for.
          </P>
          <Button
            className={style['ToggleAllButton']}
            on_click={toggleAll}
            size="large"
            variant="tertiary"
          >
            Toggle all
          </Button>
          {apisWithStatus?.map(({ id, name }) => (
            <Checkbox
              key={id}
              label={name}
              top="small"
              {...register.checkbox(`status.${id}`)}
            />
          ))}
          <FormStatus
            id="checkbox-status"
            text={getError('status')}
            title="Status"
            top="small"
          />
        </FormRow>
        <FormRow top="medium">
          <Button
            disabled={submitting}
            size="large"
            status={formError}
            type="submit"
          >
            Confirm
          </Button>
        </FormRow>
      </FormSet>
      <Dialog
        confirmText="Got it!"
        description={
          <P>
            We&apos;ve sent a confirmation email to {formEmailValue}. If you
            didn&apos;t receive it, check your spam filter.
          </P>
        }
        hideDecline
        onConfirm={() => navigate('/status')}
        openState={open}
        title="Success! An email is on its way"
        variant="confirmation"
      />
    </ModalPage>
  );
}
