import { Badge, Icon, P, Space } from '@dnb/eufemia';
import {
  cloud as CloudIcon,
  hourglass as HourglassIcon,
  stop as StopIcon,
} from '@dnb/eufemia/icons';
import {
  Decision,
  type ScopeEntitlement,
} from '@portals/shared/portal/AppApiEntitlementDto';
import classNames from 'classnames';
import type { JSX } from 'react';

import Card from '@/components/Card';
import Divider from '@/components/Divider';

import style from './index.module.css';

const getIcon = (status: Decision) => {
  switch (status) {
    case Decision.PENDING: {
      return <HourglassIcon />;
    }
    case Decision.APPROVED: {
      return <CloudIcon />;
    }
    case Decision.DENIED: {
      return <StopIcon />;
    }
    default: {
      return <HourglassIcon />;
    }
  }
};
type ScopeCardProps = {
  scopeEntitlement: ScopeEntitlement;
  showAccessSection?: boolean;
  reviewStatus?: Decision;
  environments: string[];
};

export default function ScopeCard({
  scopeEntitlement,
  showAccessSection = true,
  reviewStatus = Decision.APPROVED,
  environments,
}: ScopeCardProps): JSX.Element {
  return (
    <Card>
      <Space
        bottom={showAccessSection ? 'small' : undefined}
        className={style['Container']}
      >
        <div className={style['Container-Scope']}>
          <span
            className={classNames(
              style['Icon'],
              style[`Icon--${reviewStatus.toLowerCase()}`],
            )}
          >
            <Icon icon={getIcon(reviewStatus)} />
          </span>
          <div className={style['Container-ScopeDetails']}>
            <P>{scopeEntitlement.scopeName}</P>
            <P size="x-small">{scopeEntitlement.scopeDescription}</P>
          </div>
        </div>
      </Space>
      {showAccessSection && (
        <>
          <Divider />
          <P size="x-small" top="small">
            Current environment access:
            {environments.sort().map((env) => (
              <Badge
                className={style['Badge']}
                content={env}
                key={env}
                left="x-small"
              />
            ))}
          </P>
        </>
      )}
    </Card>
  );
}
