import {
  InteractionRequiredAuthError,
  PublicClientApplication,
  type RedirectRequest,
} from '@azure/msal-browser';

export const loginRequest: RedirectRequest = {
  scopes: ['openid', 'profile', 'email', `api://${AZURE_CLIENT_ID}/User`],
  domainHint: 'dnb.no',
};

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${AZURE_TENANT}`,
    redirectUri: AZURE_REDIRECT_URI,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
});
await msalInstance.initialize();

export async function signInWithAzure(): Promise<void> {
  return msalInstance.loginRedirect(loginRequest);
}

export async function logoutWithAzure(): Promise<void> {
  return msalInstance.logoutRedirect();
}

export async function acquireTokenSilentWithAzure(
  forceRefresh: boolean = false,
): Promise<void> {
  const [account] = msalInstance.getAllAccounts();

  if (!account) return signInWithAzure();

  try {
    await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
      forceRefresh,
    });
  } catch (error) {
    // Catch interaction_required errors and call interactive method to resolve
    if (error instanceof InteractionRequiredAuthError) {
      try {
        await msalInstance.acquireTokenRedirect({
          ...loginRequest,
          account,
        });
      } catch (error_) {
        // eslint-disable-next-line no-console
        console.log(error_);
      }
    } else {
      localStorage.clear();
      location.reload();
    }
  }
}

/**
 * Call {@link isSignedInWithAzure} before calling this method.
 */
export async function getAzureToken(): Promise<string> {
  const [account] = msalInstance.getAllAccounts();

  const tokenRequest = { ...loginRequest, account };

  const response = await msalInstance.acquireTokenSilent(tokenRequest);
  return response.accessToken;
}

/**
 * 1. Check whether the cache contains any accounts.
 * 2. Check whether the account in the cache is still valid for fetching a token. This check is required as the
 *  account might be present in the cache, but the refresh-token could have expired.
 */
export async function isSignedInWithAzure(): Promise<boolean> {
  const [account] = msalInstance.getAllAccounts();
  if (!account) return false;

  const tokenRequest = { ...loginRequest, account };
  try {
    await msalInstance.acquireTokenSilent(tokenRequest);
    return true;
  } catch {
    return false;
  }
}
