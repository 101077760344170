import { Div, Skeleton } from '@dnb/eufemia';
import Markdown from '@portals/shared-frontend/components/Markdown';
import { type JSX } from 'react';

import GuideTableOfContent from './GuideTableOfContent';

import { Page } from '@/pages/api-documentation/styles/Api.Reference.styles';

interface Props {
  guide: string | undefined;
  style?: React.CSSProperties;
  hideSearch?: boolean;
  loading?: boolean;
  topChild?: JSX.Element;
  error?: JSX.Element | boolean;
}

// TODO: fix the need for custom style handling pre breakouts
const Guide = ({
  guide,
  style,
  hideSearch = false,
  topChild = undefined,
  loading,
  error = undefined,
}: Props): JSX.Element => {
  return (
    <Page removeTopPaddingOfleft={Boolean(topChild)}>
      <GuideTableOfContent
        guide={guide}
        hideSearch={hideSearch}
        topChild={topChild}
      />
      <Skeleton show={loading && !guide}>
        <Div className="content" style={style}>
          {!loading && error}
          {guide && <Markdown>{guide}</Markdown>}
        </Div>
      </Skeleton>
    </Page>
  );
};

export default Guide;
