import { deepCopy } from '@portals/shared/common/utils';
import { ApiDto } from '@portals/shared/portal/ApiDto';
import { useMemo } from 'react';
import useSWR from 'swr';

import PageTimelineContent from '@/pages/explorer/apis/page/regulatory-apis/PageTimelineContent';

import ApiPage from '../../components/ApiPage';
import { API_EXPLORER_TABS, EXTERNAL_CLASSIFICATIONS } from '../../content';

export default function RegulatoryApis() {
  const { data: apis, mutate } = useSWR<ApiDto[]>('/api?type=REST');
  const regulatoryApis = useMemo(() => {
    return apis
      ?.filter((api) => EXTERNAL_CLASSIFICATIONS.has(api.classification))
      .filter(({ tags }) => tags.includes('PSD2'));
  }, [apis]);

  return (
    <ApiPage
      apiType={API_EXPLORER_TABS.Regulatory}
      apis={regulatoryApis || null}
      onApiFavouriteChange={(apiId, isFavorite) =>
        mutate((apis) => {
          if (apis) {
            const updatedApis = deepCopy(apis);
            const index = updatedApis.findIndex((api) => api.id === apiId);
            updatedApis[index].isFavorite = isFavorite;
            return updatedApis;
          }
          return;
        }, false)
      }
      title="Regulatory APIs"
    >
      <PageTimelineContent />
    </ApiPage>
  );
}
