import {
  Button,
  Checkbox,
  FormLabel,
  FormRow,
  FormSet,
  FormStatus,
  HelpButton,
  Input,
  Radio,
} from '@dnb/eufemia';
import { SeverityClassification } from '@portals/shared/admin/ApiDto';
import type { ApiInfoDto } from '@portals/shared/portal/ApiDto';
import { OAuthIntegrationInfoSchema } from '@portals/shared/portal/ApiDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';
import { type JSX, useCallback, useEffect, useState } from 'react';

import { makeApiAvailableForOauth } from '@/api/app';
import LoadingModal from '@/components/LoadingModal';
import ServiceNowIdentifierViewer from '@/components/ServiceNowIdentifierViewer';
import Toast from '@/components/Toast';
const SEVERITY_CLASSIFICATION_OPTIONS = {
  [SeverityClassification.OPEN]: 'Open',
  [SeverityClassification.INTERNAL]: 'Internal',
  [SeverityClassification.CONFIDENTIAL]: 'Confidential',
  [SeverityClassification.STRICTLY_CONFIDENTIAL]: 'Strictly Confidential',
};

type OAuthIntegrationInfoRequesterProps = {
  api: ApiInfoDto;
  apiUpdated: () => void;
};

export default function OAuthIntegrationInfoRequesterComp({
  api,
  apiUpdated,
}: OAuthIntegrationInfoRequesterProps): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [successMessage, setSuccessMessage] = useState<string | null>();

  const {
    register,
    controller,
    controller: { setValue },
    handleSubmit,
    submitting,
  } = useEufemiaForm(OAuthIntegrationInfoSchema, {});

  useEffect(() => {
    if (api.serviceNowIdentifier)
      setValue('serviceNowIdentifier', api.serviceNowIdentifier);
    if (api.severityClassification)
      setValue('severityClassification', api.severityClassification);
    if (api.piiExposed) setValue('piiExposed', api.piiExposed);
    if (api.gdprCompliant) setValue('gdprCompliant', api.gdprCompliant);
    switch (api.approvalStage) {
      case 'test': {
        setValue('approvalStageTest', true);
        setValue('approvalStageLive', true);

        break;
      }
      case 'live': {
        setValue('approvalStageTest', false);
        setValue('approvalStageLive', true);

        break;
      }
      case 'public': {
        setValue('approvalStageTest', false);
        setValue('approvalStageLive', false);

        break;
      }
      default: {
        setValue('approvalStageTest', false);
        setValue('approvalStageLive', true);
      }
    }
  }, [
    api.serviceNowIdentifier,
    api.severityClassification,
    api.piiExposed,
    api.gdprCompliant,
    setValue,
    api.approvalStage,
  ]);

  const onSubmit = handleSubmit(
    useCallback(
      async (data) => {
        try {
          setErrorMessage(null);
          await makeApiAvailableForOauth(api.id, data);
          setSuccessMessage('Changes saved successfully');
          apiUpdated();
        } catch (error: unknown) {
          if (ApiError.isApiError(error)) {
            setErrorMessage(error.body.message);
          } else {
            throw error;
          }
        }
      },
      [api.id, apiUpdated],
    ),
  );

  return (
    <>
      {submitting && <LoadingModal />}
      <FormSet on_submit={onSubmit} vertical>
        <FormRow vertical>
          <Input
            label={
              <>
                ServiceNow Identifier (BA/BSN)
                <HelpButton
                  left="small"
                  size="small"
                  title="ServiceNow Identifier (BA/BSN)"
                >
                  The Business application id (BAXXXXX) or Service offering id
                  (BSNXXXXXXX) to identify the integration service in
                  ServiceNow.
                </HelpButton>
              </>
            }
            placeholder="BAXXXXX / BSNXXXXXXX"
            size={40}
            stretch
            {...register.input('serviceNowIdentifier')}
          />
        </FormRow>
        <FormRow top="small" vertical>
          <ServiceNowIdentifierViewer
            onChange={(value) => {
              setValue('serviceNowIdentifierSysId', value);
            }}
            serviceNowIdentifier={controller.values.serviceNowIdentifier}
          />
        </FormRow>
        <FormRow top="medium" vertical>
          <FormLabel for_id="severity-classification">
            Severity classification
            <HelpButton left="x-small" size="small">
              How is the severity of the data exposed by this service
              integration classified.
            </HelpButton>
          </FormLabel>
        </FormRow>
        <FormRow>
          <Radio.Group
            id="severity-classification"
            layout_direction="column"
            {...register.radioGroup('severityClassification')}
          >
            {Object.entries(SEVERITY_CLASSIFICATION_OPTIONS).map(
              ([value, label]) => (
                <Radio key={value} label={label} value={value} />
              ),
            )}
          </Radio.Group>
        </FormRow>

        <FormRow top="medium">
          <Checkbox
            label="Exposing personal identifiable information"
            {...register.checkbox('piiExposed')}
            suffix={
              <HelpButton size="small">
                This should be checked of if the integration service exposes
                personal identifiable information.
              </HelpButton>
            }
          />
        </FormRow>
        <FormRow top="medium">
          <Checkbox
            label="GDPR Compliant"
            {...register.checkbox('gdprCompliant')}
            suffix={
              <HelpButton size="small">
                This should be checked to see if the integration service is
                required to comply with GDPR regulations.
              </HelpButton>
            }
          />
        </FormRow>

        <FormRow top="medium">
          <Checkbox
            label="Test (Ciam UAT) client creation requires approval"
            {...register.checkbox('approvalStageTest')}
            suffix={
              <HelpButton size="small">
                Select this if your integration service exposes sensitive
                information in test environment and you need to approve client
                creation for test environment.
              </HelpButton>
            }
          />
        </FormRow>
        <FormRow top="medium">
          <Checkbox
            label="Live (Ciam Prod) Client creation requires approval"
            {...register.checkbox('approvalStageLive')}
            suffix={
              <HelpButton size="small">
                This should be selected for most of the integration services
                unless the data exposed by integration service is public.
              </HelpButton>
            }
          />
        </FormRow>
        <FormStatus bottom="large" text={errorMessage} top="medium" />

        <FormRow>
          <Button
            text="Save changes"
            top="medium"
            type="submit"
            variant="secondary"
          />
        </FormRow>

        {successMessage && (
          <Toast message={successMessage} position="bottom" variant="success" />
        )}
      </FormSet>
    </>
  );
}
