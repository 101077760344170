import type { JSX } from 'react';

import HeroPage from '@/components/HeroPage';

import DeveloperPortalGuideContent from '../../components/Guide/DeveloperPortalGuideContent';

import LandingPageBanner from '@/illustrations/LandingPageBanner.png?url';

export default function LandingNew(): JSX.Element {
  return (
    <HeroPage
      heroIllustration={LandingPageBanner}
      noContainer
      noTopMargin
      subtitle="Follow these steps to start consuming our APIs"
      title="Getting started"
    >
      <DeveloperPortalGuideContent contentSlug="getting-started" />
    </HeroPage>
  );
}
