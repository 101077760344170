import { H2, Link, Section, Skeleton } from '@dnb/eufemia';
import type { ApiDto } from '@portals/shared/portal/ApiDto';
import type { JSX } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';

import Page from '@/components/Page';
import { createDummyApi } from '@/dummyData';

import style from './Sitemap.module.css';

import siteMapIllustrationUrl from './Sitemap.svg?url';

type SiteMapItem = {
  name: string;
  path: string;
};

type SiteMapSection = {
  name: string;
  items: SiteMapItem[];
  skeleton?: boolean;
};

const DUMMY_APIS = new Array(10).fill(null).map((_, i) => createDummyApi(i));

export default function Sitemap(): JSX.Element {
  const { data: apis, isValidating: apisLoading } = useSWR<ApiDto[]>('/api');

  const hasLoaded = !apisLoading && !!apis;

  const sections: SiteMapSection[] = [
    {
      name: 'DNB Developer',
      items: [
        { name: 'Landing page', path: '/' },
        { name: 'Getting started', path: '/getting-started' },
        { name: 'Sign up', path: '/sign-up' },
        {
          name: 'API explorer',
          path: '/explorer/apis',
        },
        { name: 'News', path: '/news' },
        { name: 'Login', path: '/login' },
        { name: 'Digital channel statistics', path: '/channel-statistics' },
        { name: 'API status', path: '/status' },
        { name: 'Forgotten password', path: '/login/forgotten-password' },
      ],
    },
    {
      name: 'API products',
      skeleton: !hasLoaded,
      items: (hasLoaded ? apis : DUMMY_APIS)
        .filter((api) => typeof api.productPageUrl === 'string')
        .map((api) => ({
          name: api.name,
          path: String(api.productPageUrl),
        })),
    },

    {
      name: 'Documentation',
      skeleton: !hasLoaded,
      items:
        (hasLoaded ? apis : DUMMY_APIS)
          .filter((api) => !!api.currentVersion)
          .map((api) => ({
            name: api.name,
            path: `/documentation/${api.slug}/@default/@latest`,
          })) ?? [],
    },
    {
      name: 'Support',
      items: [
        { name: 'Help Center', path: '/help-center' },
        { name: 'Privacy Policy', path: '/privacy-policy' },
        { name: 'Careers', path: '/jobs' },

        { name: 'Terms of use', path: '/terms-of-use' },
      ],
    },
  ];

  return (
    <Page
      illustration={
        <img className={style['Illustration']} src={siteMapIllustrationUrl} />
      }
      spacing={false}
      subtitle="Sitemap"
      title="All the pages"
    >
      {sections.map((section, i) => (
        <Section
          key={section.name}
          spacing="x-large"
          style_type={i % 2 ? 'mint-green-12' : 'white'}
        >
          <div className={style['SitemapSection']}>
            <H2>{section.name}</H2>
            <Skeleton show={section.skeleton}>
              <ul>
                {section.items.map((item) => (
                  <li key={item.path}>
                    <Link element={RouterLink} to={item.path}>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Skeleton>
          </div>
        </Section>
      ))}
    </Page>
  );
}
