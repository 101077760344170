import {
  Avatar,
  Badge,
  H2,
  InfoCard,
  Input,
  P,
  Section,
  Space,
  Span,
} from '@dnb/eufemia';
import { loupe as LoupeIcon } from '@dnb/eufemia/icons';
import type { TeamDetailsDto } from '@portals/shared/portal/TeamDto';
import { format } from 'date-fns';
import { type JSX, useMemo, useState } from 'react';

import Card from '@/components/Card';
import Divider from '@/components/Divider';

import style from './index.module.css';

type CollaboratorsProps = {
  team: TeamDetailsDto;
};
export default function Collaborators({
  team,
}: CollaboratorsProps): JSX.Element {
  const [search, setSearch] = useState('');

  const filteredMembers = useMemo(
    () =>
      team.members.filter(({ firstName, lastName, email }) =>
        [firstName, lastName, email].some((property) =>
          property.toLowerCase().includes(search.toLowerCase()),
        ),
      ),
    [team, search],
  );

  return (
    <Section
      className={style['Container']}
      spacing="x-large"
      style_type="black-3"
    >
      <H2>Collaborators</H2>

      <Space className={style['Actions']} top="small">
        <Input
          icon={LoupeIcon}
          on_change={({ value }) => setSearch(value)}
          placeholder="Search collaborators"
        />
      </Space>

      {filteredMembers.length === 0 && (
        <InfoCard
          centered
          text="We could not find the user you are looking for."
          top="small"
        />
      )}

      <Space className={style['Members']} top="small">
        {filteredMembers.map((member) => (
          <Card className={style['MemberTile']} key={member.id}>
            <div className={style['MemberBoxDetails']}>
              <div className={style['MemberDetails']}>
                <Avatar
                  className={style['Avatar']}
                  right="small"
                  variant="secondary"
                >
                  {member.firstName[0]}
                </Avatar>

                <Space className={style['Name']}>
                  <P>
                    {member.firstName} {member.lastName}
                  </P>
                  <Span className={style['EmailAndDate']}>{member.email}</Span>
                </Space>
              </div>

              <Space className={style['DateDetails']}>
                <P>Joined</P>
                <Span className={style['EmailAndDate']}>
                  {format(new Date(member.joinedAt), 'dd.MM.yyy')}
                </Span>
              </Space>
            </div>

            <Divider />
            <div>
              <Badge
                className={style['Badge']}
                content={member.isAdmin ? 'Admin' : 'Collaborator'}
                left="x-small"
              />
            </div>
          </Card>
        ))}
      </Space>
    </Section>
  );
}
