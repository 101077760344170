import { Button, H2, Img, P, Section, Space } from '@dnb/eufemia';
import { launch as LaunchIcon } from '@dnb/eufemia/icons';
import type { JSX } from 'react';

import Container from '@/components/Container';
import Page from '@/components/Page';

import style from './Jobs.module.css';

import astronautAndPlanetCircleUrl from './AstronautAndPlanetCircle.svg?url';
import manAndWomanAtComputersIllustrationUrl from './ManAndWomanAtComputers.svg?url';

export default function Jobs(): JSX.Element {
  return (
    <Page
      description={
        <>
          <P>Do you hunger for intrapreneurship in a big company?</P>
          <P>Join our team!</P>
        </>
      }
      illustration={
        <img
          alt="Man and woman sitting infront of their comupters"
          className={style['Illustration']}
          src={manAndWomanAtComputersIllustrationUrl}
        />
      }
      noContainer
      spacing={false}
      subtitle="Careers"
      title="Your new job is here"
    >
      <Section spacing="x-large" style_type="white">
        <Container centered>
          <Container size="small">
            <H2>Who we are</H2>
            <P top="medium">
              DNB Open Banking is a rapidly growing technology team in DNB that
              is responsible for building API products and capabilities that
              help DNB stay compliant as well as generate new value through APIs
              that go beyond compliance. We do this through developer.dnb.no,
              which allows our customers to get access to DNB&apos;s data and
              services through APIs.
            </P>
            <P top="small">
              We pride ourselves on being a diverse and inclusive team.
              Consisting of different nationalities, software engineers, product
              managers and business developers, our team is both international
              and interdisciplinary. We value good teamwork and care deeply
              about building an inclusive culture in which everyone can succeed.
              Being a part of Open Banking will give you the opportunity to
              enable and drive innovation.
            </P>
          </Container>
        </Container>
      </Section>
      <Space className={style['Contact']}>
        <Container centered>
          <div className={style['Contact-content']}>
            <Img
              alt="An astronaut looking at the Earth"
              className={style['Contact-image']}
              src={astronautAndPlanetCircleUrl}
            />
            <div className={style['Contact-text']}>
              <H2>Come work with us</H2>
              <P top="small">
                We are always looking for talented people! Do you want to be
                part of our Open Banking team, check out available positions
                below.
              </P>
              <Button
                href="https://jobb.dnb.no/"
                icon={LaunchIcon}
                size="large"
                top="medium"
              >
                Browse open positions
              </Button>
            </div>
          </div>
        </Container>
      </Space>
    </Page>
  );
}
