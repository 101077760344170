import { useMemo } from 'react';

import Guide from '@/components/Guide';
import { useBackend } from '@/hooks/useBackend';
import useSWRExpect404 from '@/hooks/useSWRExpect404';
import { EventKafkaResponse } from '@/openapi/types';
import { toJsonMarkdown } from '@/utils/markdownUtils';

import EventResourceNotFoundError from '../EventResourceNotFoundError';

interface Props {
  environmentSlug: string;
  eventSlug: string;
}

export default function KafkaConfigView({ environmentSlug, eventSlug }: Props) {
  const swrExpect404 = useSWRExpect404();
  const {
    data: kafkaConfig,
    isValidating: schemasValidation,
    error,
  } = useBackend(
    `/events/{eventSlug}/environments/{environmentSlug}/kafka-config`,
    {
      params: {
        path: {
          eventSlug: eventSlug,
          environmentSlug: environmentSlug,
        },
      },
    },
    swrExpect404,
  );

  const markdownGuide = useMemo(
    () => kafkaConfig && getTopicConfig(kafkaConfig),
    [kafkaConfig],
  );

  if (error) {
    return <EventResourceNotFoundError type="kafka-config" />;
  }

  return (
    <Guide
      guide={markdownGuide}
      hideSearch
      loading={schemasValidation}
      style={{ paddingTop: '0' }}
    />
  );
}

function getTopicConfig(schema: EventKafkaResponse): string {
  const combinedInformation = `| Partitions | Replication factor | Kafka platform|
| ----------- | ----------- | ----------- |
| ${`${schema.partitions} | ${schema.replicationFactor}| ${schema.kafkaPlatform} |`}`;

  const header = `# Topic configuration `;
  const content = toJsonMarkdown(
    JSON.stringify(schema?.topicConfiguration, null, '\t'),
  );
  return combinedInformation + '\n' + header + '\n' + content;
}
