import {
  Button,
  Card,
  Drawer,
  FormRow,
  FormSet,
  Hr,
  InfoCard,
  Input,
  Link,
  P,
  Section,
  Tag,
  ToggleButton,
} from '@dnb/eufemia';
import { filter as FilterIcon } from '@dnb/eufemia/icons';
import styled from '@emotion/styled';
import {
  type ApiFromCommonRepoDto,
  COMMON_REPO_ENVIRONMENTS,
} from '@portals/shared/portal/ApiFromCommonRepoDto';
import { isInternalUser } from '@portals/shared-frontend/utils';
import { type JSX, useEffect, useMemo, useState } from 'react';
import {
  Link as RouterLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import useSWR from 'swr';

import Page from '@/components/Page';
import useReturnTo from '@/hooks/useReturnTo';
import { useUser } from '@/hooks/useUser';

import CommonRepoApiDocumentation from '../../api-documentation/CommonRepoApiDocumentation';

export default function ApiFromCommonRepo(): JSX.Element {
  const location = useLocation();
  const [classificationFilter, setClassificationFilter] = useState<string[]>(
    [],
  );
  const [, setReturnTo] = useReturnTo();
  const { user, isLoading: userLoading } = useUser();
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const { data: apis, isValidating: apisLoading } =
    useSWR<ApiFromCommonRepoDto[]>('/common-repo/apis');
  const [envFilter, setEnvFilter] = useState<string[]>([]);

  const hasLoaded = !apisLoading && !!apis;
  const isDnbUser = isInternalUser(user?.email);

  const filteredApis = useMemo(() => {
    if (!apis) return [];

    const lowerCasedQuery = query.toLowerCase();

    const basis = apis
      .filter((api) =>
        classificationFilter.length > 0
          ? classificationFilter.includes(api.classification)
          : true,
      )
      .filter((api) =>
        envFilter.length > 0
          ? envFilter.some((env) =>
              api.versions.map((v) => v.environment).includes(env),
            )
          : true,
      )
      .filter(
        (api) =>
          !lowerCasedQuery || api.title.toLowerCase().includes(lowerCasedQuery),
      );

    return basis;
  }, [apis, envFilter, classificationFilter, query]);

  useEffect(() => {
    if (userLoading || isDnbUser) return;

    if (user) {
      // TODO: Set global 404 stuff
      navigate('/404', { replace: true });
    } else {
      setReturnTo(location.pathname);
      navigate('/login', { replace: true });
    }
  }, [location.pathname, navigate, setReturnTo, userLoading, isDnbUser, user]);

  const classifications = useMemo(() => {
    if (apis) {
      return [...new Set(apis.map((api) => api.classification))];
    }
    return [];
  }, [apis]);

  const apiExplorer = (
    <div className="dark-mode">
      <Page
        skeleton={!hasLoaded}
        spacing={false}
        styleType="white"
        title="API contracts"
      >
        <Section spacing="x-large" style_type="white">
          <FlexFormRow bottom="small">
            <Drawer
              right="x-small"
              title="Filters"
              triggerAttributes={{
                text: 'Filter',
                variant: 'secondary',
                icon: FilterIcon,
              }}
            >
              <FormSet vertical>
                <FormRow top="medium">
                  <ToggleButton.Group
                    label="Classification"
                    multiselect
                    on_change={({ values }) => {
                      setClassificationFilter([...values]);
                    }}
                    values={classificationFilter}
                    variant="checkbox"
                  >
                    {classifications.map((classification) => (
                      <CapitalizedToggleButton
                        key={classification}
                        text={classification}
                        value={classification}
                      />
                    ))}
                  </ToggleButton.Group>
                </FormRow>
                <FormRow top="medium">
                  <ToggleButton.Group
                    label="Environments"
                    multiselect
                    on_change={({ values }) => {
                      setEnvFilter([...values]);
                    }}
                    values={envFilter}
                    variant="checkbox"
                  >
                    {COMMON_REPO_ENVIRONMENTS.map((env) => (
                      <CapitalizedToggleButton
                        key={env}
                        text={env}
                        value={env}
                      />
                    ))}
                  </ToggleButton.Group>
                </FormRow>
              </FormSet>
            </Drawer>
            <Input
              clear
              icon="loupe"
              on_change={({ value }) => setQuery(value)}
              placeholder="Search"
              top="small"
              value={query}
            />
          </FlexFormRow>
          {classificationFilter.length > 0 && (
            <FormRow bottom="small">
              <Tag.Group label="Classifications">
                <P>Classifications: </P>
                {classificationFilter.map((classification) => (
                  <CapitalizedTag
                    key={classification}
                    onDelete={() => {
                      const _classificationFilter = classificationFilter.filter(
                        (candidate) => candidate !== classification,
                      );
                      setClassificationFilter(_classificationFilter);
                    }}
                  >
                    {classification}
                  </CapitalizedTag>
                ))}
              </Tag.Group>
            </FormRow>
          )}
          {envFilter.length > 0 && (
            <FormRow bottom="small">
              <Tag.Group label="Environments">
                <P>Environments: </P>
                {envFilter.map((env) => (
                  <CapitalizedTag
                    key={env}
                    onDelete={() => {
                      const _envFilter = envFilter.filter(
                        (candidate) => candidate !== env,
                      );
                      setEnvFilter(_envFilter);
                    }}
                  >
                    {env}
                  </CapitalizedTag>
                ))}
              </Tag.Group>
            </FormRow>
          )}

          {filteredApis.length > 0 ? (
            filteredApis.map((api) => (
              <Card bottom="medium" key={api.slug}>
                <Link element={RouterLink} to={`${api.slug}/@default/@latest`}>
                  {api.title}
                </Link>
                <SubText top="small">{api.classification}</SubText>
                {api.description && (
                  <P bottom="small" top="small">
                    {api.description.length > 300
                      ? `${api.description.slice(0, 300)} ...`
                      : api.description}
                  </P>
                )}
                <Divider />
                <VersionBadges api={api} />
              </Card>
            ))
          ) : (
            <InfoCard text="No APIs match the selected filters." />
          )}
        </Section>
      </Page>
    </div>
  );

  return (
    <Routes>
      <Route element={apiExplorer} index />
      <Route
        element={<CommonRepoApiDocumentation apis={apis || []} />}
        path=":apiSlug/:environment/:version"
      />
      <Route
        element={<CommonRepoApiDocumentation apis={apis || []} />}
        path=":apiSlug/:environment/:version/:tab"
      />
    </Routes>
  );
}

function VersionBadges({ api }: { api: ApiFromCommonRepoDto }): JSX.Element {
  const latestVersion = useMemo(() => {
    return COMMON_REPO_ENVIRONMENTS.map((env) => {
      const versionsPerEnv = api.versions.filter(
        (version) => version.environment === env,
      );
      return versionsPerEnv.some((v) => v.isLatest)
        ? versionsPerEnv.find((v) => v.isLatest)
        : versionsPerEnv[0];
    }).filter(Boolean);
  }, [api]);
  return (
    <>
      {latestVersion.map((version) => (
        <CapitalizedButton
          element={RouterLink}
          icon="chevron_right"
          key={`${version?.environment}`}
          right="small"
          size="medium"
          text={version?.environment}
          to={`${api.slug}/${version?.environment}/${version?.version}`}
          variant="secondary"
        />
      ))}
    </>
  );
}

const Divider = styled(Hr)`
  color: var(--color-black-8);
  padding-bottom: var(--spacing-small);
`;

const SubText = styled(P)`
  color: var(--color-black-55);
  text-transform: capitalize;
`;

const CapitalizedToggleButton = styled(ToggleButton)`
  .dnb-button {
    text-transform: capitalize;
  }
`;

const CapitalizedButton = styled(Button)`
  text-transform: capitalize;
`;

const CapitalizedTag = styled(Tag)`
  text-transform: capitalize;
`;

const FlexFormRow = styled(FormRow)`
  .dnb-form-row__content {
    justify-content: space-between;
  }
`;
