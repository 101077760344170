import { Flex, H1, P, Space } from '@dnb/eufemia';
import styled from '@emotion/styled';

const ErrorSpacing = styled.div`
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface props {
  type: 'kafka-config' | 'schema' | 'release' | 'version';
}

export default function EventResourceNotFoundError({ type }: props) {
  if (type === 'version') {
    <ErrorSpacing>
      <Space bottom={10}>
        <Flex.Vertical align="center" bottom={10} justify="space-around">
          <H1 size="x-large">No schema found</H1>
          <P>There was no schema found on the selected version.</P>
        </Flex.Vertical>
      </Space>
    </ErrorSpacing>;
  }
  return (
    <ErrorSpacing>
      <Space bottom={10}>
        <Flex.Vertical align="center" bottom={10} justify="space-around">
          <H1 size="x-large">No {type} present</H1>
          <P>There was no {type} found on the selected environment.</P>
        </Flex.Vertical>
      </Space>
    </ErrorSpacing>
  );
}
