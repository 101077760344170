import { type JSX } from 'react';

import DeveloperPortalGuideContent from '@/components/Guide/DeveloperPortalGuideContent';
import HeroPage from '@/components/HeroPage';

import LandingPageBanner from '@/illustrations/LandingPageBanner.png?url';

export default function OAuth2AuthenticationGuide(): JSX.Element {
  return (
    <HeroPage
      heroIllustration={LandingPageBanner}
      noContainer
      noTopMargin
      title="OAuth2 Authentication"
    >
      <DeveloperPortalGuideContent contentSlug="oauth2-authentication-internal" />
    </HeroPage>
  );
}
