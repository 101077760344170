import { Accordion, Dropdown } from '@dnb/eufemia';
import styled from '@emotion/styled';

export const StyledAccordion = styled(Accordion)`
  & .dnb-accordion__header--expanded:hover {
    background-color: var(--color-local-interaction-green);
    border: 0px solid transparent;
  }

  & .dnb-accordion__header--expanded {
    background-color: var(--color-local-interaction-green);
    border: 0px solid transparent;
  }

  & .dnb-accordion__header:hover {
    background-color: var(--color-local-interaction-green);
    border: 0px solid transparent;
  }

  & .dnb-accordion__header {
    box-shadow: none;
    color: var(--color-emerald-green);
  }
`;

export const StyledDropdown = styled(Dropdown)`
  & .dnb-drawer-list__list {
    top: 1rem;
    width: 15rem;
  }

  & .dnb-drawer-list__option__inner {
    padding: 0;
  }

  & .dnb-alignment-helper {
    display: none;
  }

  & #menu-listbox {
    box-shadow: 0.15rem 0.15rem 0.15rem 0.15rem rgb(0 0 0 / 20%);
  }

  & #profile-listbox {
    box-shadow: 0.15rem 0.15rem 0.15rem 0.15rem rgb(0 0 0 / 20%);
  }
`;
