import { useLocalStorage } from '@portals/shared-frontend/hooks';
import { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { loginRequest, msalInstance } from '@/auth/azure';
import CenteredProgressIndicator from '@/components/ProgressIndicator';
import AuthContext from '@/hooks/AuthContext';
import useReturnTo from '@/hooks/useReturnTo';

export default function SsoAzureLogin() {
  const [, setLastLoggedInEmail] = useLocalStorage(
    `last-logged-in-internal-user-email`,
    '',
  );
  const navigate = useNavigate();
  const [returnTo] = useReturnTo();
  const { isSignedIn, isLoading, reload: reloadAuth } = useContext(AuthContext);

  const signIn = useCallback(async () => {
    try {
      const previousRedirectResponse =
        await msalInstance.handleRedirectPromise();
      if (previousRedirectResponse === null) {
        await msalInstance.loginRedirect(loginRequest);
      } else {
        setLastLoggedInEmail(previousRedirectResponse.account.username);
        reloadAuth();
      }
    } catch {
      navigate('/login/failure');
    }
  }, [navigate, reloadAuth, setLastLoggedInEmail]);

  useEffect(() => {
    if (isSignedIn) {
      navigate(returnTo ?? '/');
    } else if (!isLoading) {
      signIn();
    }
  }, [isSignedIn, isLoading, navigate, returnTo, signIn]);

  return <CenteredProgressIndicator />;
}
