import { Card, Link, Switch } from '@dnb/eufemia';
import {
  ApiType,
  ApiWithStatusAndScopesDto,
} from '@portals/shared/portal/ApiDto';
import type { ChangelogDto } from '@portals/shared/portal/ChangelogDto';
import Markdown from '@portals/shared-frontend/components/Markdown';
import { useLocalStorage } from '@portals/shared-frontend/hooks';
import useSWR from 'swr';

import Divider from '@/components/Divider';
import useSWRExpect404 from '@/hooks/useSWRExpect404';
import AdditionalInformation from '@/pages/profile/apps/application/api-stats/AdditionalInformationSection/AdditionalInformation';

import { enableApiFaq, enableApiStatus, enableChangelogs } from './constants';

interface Props {
  appId: string;
  api: ApiWithStatusAndScopesDto;
}

function hasMissingInformation(
  api: ApiWithStatusAndScopesDto,
  changelogs: ChangelogDto[] | undefined,
): boolean {
  let requiredInfo: boolean[];
  if (api.type === ApiType.REST) {
    requiredInfo = [
      api.attachable,
      api.enableServiceNowRequest,
      !!(changelogs && changelogs.length > 0),
      api.enableStatus,
      api.enableFaq,
    ];
  } else {
    requiredInfo = [api.attachable, api.enableServiceNowRequest];
  }

  return requiredInfo.some((bool) => !bool);
}

export default function AdditionalInformationSection({ appId, api }: Props) {
  const swrExpect404 = useSWRExpect404();
  const { data: changelogs, isValidating: loadingChangelogs } = useSWR<
    ChangelogDto[]
  >(`/api/${api.slug}/environments/@default/changelogs`, swrExpect404);

  const [displayHelpText, setDisplayHelpText] = useLocalStorage(
    'displayHelpText',
    false,
  );

  const isRestApi = api.type === ApiType.REST;
  const missingInformation = hasMissingInformation(api, changelogs);

  return (
    <Card skeleton={loadingChangelogs} stack>
      {missingInformation && (
        <Switch
          bottom="small"
          checked={Boolean(displayHelpText)}
          label="Display help message"
          onChange={(e) => setDisplayHelpText(e.checked)}
        />
      )}
      <AdditionalInformation
        content="Consumer apps can request access"
        displayHelpText={displayHelpText}
        statusMessage={<StatusMessage />}
        value={api.attachable}
      />
      <Divider />
      <AdditionalInformation
        content="ServiceNow management enabled"
        displayHelpText={displayHelpText}
        statusMessage={<StatusMessage />}
        value={api.enableServiceNowRequest}
      />
      {isRestApi && (
        <>
          <Divider />
          <AdditionalInformation
            codeBlock={
              <Markdown>
                {enableChangelogs(api.slug, appId!, '@default', '@latest')}
              </Markdown>
            }
            content="Changelogs enabled"
            displayHelpText={displayHelpText}
            statusMessage={
              <StatusMessage
                context="Changelogs"
                helpMessage={false}
                link="https://developer.dnb.no/documentation/developer-portal-api/prod/@latest/reference#tags/Changelog"
              />
            }
            value={!!(changelogs && changelogs.length > 0)}
          />
          <Divider />
          <AdditionalInformation
            codeBlock={<Markdown>{enableApiStatus(api.slug, appId!)}</Markdown>}
            content="Status reporting enabled"
            displayHelpText={displayHelpText}
            statusMessage={
              <StatusMessage
                context="status reporting"
                helpMessage={false}
                link="https://developer.dnb.no/documentation/developer-portal-api/prod/@latest/reference#tags/APIs/operation/patch/apis/api-slug"
              />
            }
            value={api.enableStatus}
          />
          <Divider />
          <AdditionalInformation
            codeBlock={<Markdown>{enableApiFaq(api.slug, appId!)}</Markdown>}
            content="FAQ enabled"
            displayHelpText={displayHelpText}
            statusMessage={
              <StatusMessage
                context="FAQ"
                helpMessage={false}
                link="https://developer.dnb.no/documentation/developer-portal-api/prod/@latest/guide"
              />
            }
            value={api.enableFaq}
          />
        </>
      )}
    </Card>
  );
}

type StatusMessageProps = {
  link?: string;
  context?: string;
  helpMessage?: boolean;
};

function StatusMessage({
  link,
  context,
  helpMessage = true,
}: StatusMessageProps) {
  return helpMessage ? (
    <>
      Contact the portals team to get started. You can reach them on the
      following slack channel:{' '}
      <Link
        href="https://dnb.enterprise.slack.com/archives/CDXF91B7E"
        target="_blank"
      >
        #developer-portal-help
      </Link>
    </>
  ) : (
    <>
      Get started with {context} for your API, by using the{' '}
      <Link href={link} target="_blank">
        developer portal API
      </Link>
    </>
  );
}
