import { AccessTypes } from '@portals/shared/admin/ApiDto';
import {
  type ApiDto,
  ApiType,
  SeverityClassification,
} from '@portals/shared/portal/ApiDto';
import type { AppDto } from '@portals/shared/portal/AppDto';
import type { ContentDto } from '@portals/shared/portal/ContentDto';
import type {
  AppDetailsDto,
  TeamDetailsDto,
  TeamInUserDetailsDto,
  TeamMemberDto,
  TeamWithAppDetailsDto,
} from '@portals/shared/portal/TeamDto';
import type { UserDetailsDto } from '@portals/shared/portal/UserDto';

interface DummyDataFactory<T> {
  (id: number, overrides?: Partial<T>): T;
}

export const createDummyApi: DummyDataFactory<ApiDto> = (
  id,
  overrides = {},
) => ({
  id: `api-id-${id}`,
  slug: `api-slug-${id}`,
  name: 'Some API',
  description: 'API description',
  classification: 'domain',
  environments: [],
  deprecated: false,
  attachable: true,
  availableInLiveMode: true,
  enableStatus: true,
  approvalStage: 'public',
  stage: 'launched',
  currentVersion: '1.0.0',
  upcomingVersion: '2.0.0',
  isPublic: true,
  icon: 'some-icon',
  documentationAnchor: null,
  restricted: false,
  tags: ['Tag 1', 'Tag 2'],
  productPageUrl: `/some/url/${id}`,
  termsOfUse: 'Hello world!',
  termsOfUseUpdatedAt: 1646827439920,
  teams: [],
  organization: null,
  ordering: 0,
  accessType: AccessTypes.API_KEYS,
  serviceNowIdentifier: 'BA12345',
  enableServiceNowRequest: false,
  enableFaq: false,
  piiExposed: false,
  gdprCompliant: false,
  severityClassification: SeverityClassification.INTERNAL,
  type: ApiType.REST,
  ...overrides,
});

export const createDummyTeamInUserDetails: DummyDataFactory<
  TeamInUserDetailsDto
> = (id, overrides = {}) => ({
  id: `team-${id}`,
  name: 'Team name',
  isAdmin: true,
  description: 'Some description',
  numberOfMembers: 1,
  numberOfApps: 2,
  organization: {
    domain: '',
    name: '',
    id: '12345',
  },
  ...overrides,
});

export const createDummyUser: DummyDataFactory<UserDetailsDto> = (
  id,
  overrides = {},
) => ({
  id: `user-${id}`,
  firstName: 'User',
  lastName: 'Usersen',
  email: `user-${id}@dnb.no`,
  isEditableUser: false,
  organizationId: `organization-${id}`,
  organizationName: 'DNB',
  organizationAcknowledged: true,
  apps: new Array(3).fill(0).map((_, i) => createDummyAppDetails(i)),
  teams: new Array(3).fill(0).map((_, i) => createDummyTeamInUserDetails(i)),
  termsOfUseAcceptedAt: '2022-04-01T10:42:37.428Z',
  latestTermsOfUseAccepted: true,
  mfaEnabled: false,
  hasLiveAccess: false,
  ...overrides,
});

export const createDummyTeamMember: DummyDataFactory<TeamMemberDto> = (
  id,
  overrides = {},
) => ({
  id: `user-${id}`,
  email: `user-${id}@dnb.no`,
  firstName: 'User',
  lastName: 'Usersen',
  isAdmin: true,
  organizationId: `organization-${id}`,
  joinedAt: '2022-04-01T10:42:37.428Z',
  mfaEnabled: false,
  ...overrides,
});

export const createDummyTeam: DummyDataFactory<TeamDetailsDto> = (
  id,
  overrides = {},
) => ({
  id: `team-${id}`,
  name: `Team ${id}`,
  description: 'Team description',
  apps: new Array(3).fill(0).map((_, i) => createDummyApp(i)),
  members: new Array(3).fill(0).map((_, i) => createDummyTeamMember(i)),
  organization: {
    id: `organization-${id}`,
    domain: `dnb.no`,
    name: 'DNB',
  },
  hasLiveAccess: true,
  createdAt: '2022-04-01T10:42:37.428Z',
  updatedAt: '2022-04-01T10:42:37.428Z',
  projects: [],
  ...overrides,
});

export const createDummyTeamWithAppDetails: DummyDataFactory<
  TeamWithAppDetailsDto
> = (id, overrides = {}) => ({
  id: `team-${id}`,
  name: `Team ${id}`,
  description: 'Team description',
  apps: new Array(3).fill(0).map((_, i) => createDummyAppDetails(i)),
  members: new Array(3).fill(0).map((_, i) => createDummyTeamMember(i)),
  organization: {
    id: `organization-${id}`,
    domain: `dnb.no`,
    name: 'DNB',
  },
  hasLiveAccess: true,
  createdAt: '2022-04-01T10:42:37.428Z',
  updatedAt: '2022-04-01T10:42:37.428Z',
  projects: [],
  ...overrides,
});

export const createDummyApp: DummyDataFactory<AppDto> = (
  id,
  overrides = {},
) => ({
  id: `app-${id}`,
  name: 'Dummy app',
  description: 'App description',
  owner: {
    id: `team-${id}`,
    type: 'team',
  },
  apiKeyName: `api-key-${id}`,
  testMode: {
    apiKey: null,
    clientId: null,
    redirectUris: [],
  },
  liveMode: {
    apiKey: null,
    clientId: null,
    redirectUris: [],
    access: false,
  },
  createdAt: '2022-04-01T10:42:37.428Z',
  updatedAt: '2022-04-01T10:42:37.428Z',
  authenticationType: 'aws',
  apiKeys: [],
  accessType: AccessTypes.API_KEYS,
  internal: true,
  ...overrides,
});

export const createDummyAppDetails: DummyDataFactory<AppDetailsDto> = (
  id,
  overrides = {},
) => ({
  app: {
    id: `app-${id}`,
    name: 'Dummy app',
    description: 'App description',
    owner: {
      id: `team-${id}`,
      type: 'team',
    },
    accessType: 'API_KEYS',
    apiKeyName: '',
    createdAt: '2022-04-01T10:42:37.428Z',
    updatedAt: '2022-04-01T10:42:37.428Z',
    internal: false,
  },
  consumedLiveModeApis: [],
  consumedTestModeApis: [],
  exposedApis: [],
  isProviderApp: false,
  ...overrides,
});

export const createDummyContent: DummyDataFactory<ContentDto> = (
  id,
  overrides = {},
) => ({
  id: `content-${id}`,
  title: 'dummy title',
  slug: 'dummy-slug',
  path: 'dummy-path',
  parent: 'dummy-parent',
  isPublic: true,
  publishedStatus: 1,
  blob: { content: 'I have a lot of info' },
  templateId: '',
  teams: [],
  createdAt: '',
  organizations: [],
  parentId: null,
  sortIndex: 100,
  updatedAt: '',
  ...overrides,
});
