import { H2, H3, Link, P } from '@dnb/eufemia';
import type { JSX } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Page from '@/components/Page';
import { ScrollMenu, type ScrollMenuElement } from '@/components/ScrollMenu';

import style from './PrivacyPolicy.module.css';

const MENU_ITEMS: ScrollMenuElement[] = [
  { id: 'privacy-notice', title: 'Privacy Notice' },
  { id: 'data-types', title: 'Types of data we collect' },
  {
    id: 'data-sources',
    title: 'Where do we gather your data from?',
  },
  { id: 'data-usage', title: 'How is your data used?' },
  { id: 'data-purpose', title: 'Legitimate grounds for processing' },
  { id: 'rights', title: 'Your rights' },
];

export default function PrivacyPolicy(): JSX.Element {
  return (
    <Page subtitle="Privacy Policy" title="DNB Developer privacy notice">
      <div className={style['PrivacyPolicy']}>
        <aside className={style['ScrollMenu']}>
          <ScrollMenu elements={MENU_ITEMS} />
        </aside>

        <div>
          <P id="privacy-notice">
            In DNB Open Banking, we take the security of your personal data
            seriously, which means that it is safe for you to use the products
            and services we offer. As an international financial services group
            we will handle your personal data in accordance with prevailing data
            protection legislation.
          </P>

          <P top="medium">
            The controller in the DNB Group will be DNB Bank ASA which processes
            personal data about you. Click{' '}
            <a
              className="dnb-anchor"
              href="https://www.dnb.no/portalfront/nedlast/en/about-us/juridisk-struktur-dnb.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>
            to see the companies in the DNB Group and find more information as
            well as contact details.
          </P>

          <P top="medium">
            This privacy protection statement contains information you are
            entitled to receive when data about you is collected from DNB
            Developer. The word “you” in this statement refers to you as a user
            of the DNB Developer Portal.
          </P>

          <H2 id="data-types" top="large">
            The types of personal data we collect
          </H2>
          <H3 top="medium">Contact information</H3>
          <P top="medium">
            DNB Developer is a discretionary offer by DNB. You must register
            through the website at{' '}
            <Link href="https://developer.dnb.no">
              https://developer.dnb.no
            </Link>{' '}
            for access to our APIs. By signing up to our Developer Portal you
            are providing registration data, including first name, surname and
            email address.
          </P>
          <P top="medium">
            The legal basis for the processing of such registration data is the
            contract on the use of DNB Developer concluded with you. The
            provision of that registration data is compulsory. If you do not
            provide the registration data, you cannot use the DNB Developer
            Portal.
          </P>

          <H2 id="data-sources" top="large">
            Sources from which we gather your personal data
          </H2>
          <H3 top="medium">From you</H3>
          <P top="medium">
            Most of the personal data DNB Developer registers will be collected
            directly from you.
          </P>
          <H3 top="medium">From cookies</H3>
          <P top="medium">
            DNB Developer Portal uses cookies (information capsules) to track
            our users&apos; web behaviour. To read more about DNB&apos;s use of
            cookies and how you can manage your cookies, please see DNB&apos;s
            website on{' '}
            <a
              className="dnb-anchor"
              href="https://www.dnb.no/en/about-us/protection-of-personal-privacy.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              Protection of personal privacy
            </a>
            under Sources from which we gather your personal data.
          </P>

          <H2 id="data-usage" top="large">
            Analysis and user insight
          </H2>
          <P top="medium">
            In order to improve the user experience of the Developer Portal we
            use the Celebrus tool. We use summary statistics to understand the
            collective user behaviour of our website. When you visit the
            Developer Portal, we will for instance store information about the
            pages which have been visited and the features which have been used.
            The aggregated data is anonymised and DNB does not analyse user
            behaviour at an individual level.
          </P>

          <H2 id="data-purpose" top="large">
            Purpose for which personal data is used
          </H2>
          <P top="medium">
            In order to personalise your user experience and to provide you with
            access to our APIs in production, we collect your first name and
            surname. This is used to approve you Developer Portal account.
          </P>
          <P top="medium">
            Your email address is used for registering purposes and for us to be
            able to contact you, as stated in the{' '}
            <Link element={RouterLink} to="/terms-of-use">
              Terms of use
            </Link>
            .
          </P>
          <P top="medium">
            We collect anonymised user behaviour data in order to improve the
            user experience of the portal.
          </P>

          <H2 id="rights" top="large">
            Your rights
          </H2>
          <H3 top="medium">Access to information and data portability</H3>
          <P top="medium">
            We store the information you provide us with when registering an
            account in the Portal. This includes your first name and surname and
            email address. We also store the applications you have registered
            and the date when this was registered, and when you requested access
            to APIs in production.
          </P>
          <P top="medium">
            Please contact us through our{' '}
            <Link element={RouterLink} to="/help-center">
              Help Center
            </Link>{' '}
            if you wish to access the personal data we have stored about you.
          </P>
          <H3 top="medium">The right to erasure of personal data</H3>
          <P top="medium">
            As long as you have one or more active agreements with us, we will
            need to store personal data about you that is linked to these
            agreements. If you haven&apos;t made any requests to our APIs during
            the past three years, DNB will delete your Developer Portal user
            account. You will be notified in advance, and when the account is
            suspended. We will delete your personal data according to legal
            requirements.
          </P>
          <P top="medium">
            If you want to terminate a service or agreement, please contact us
            through our{' '}
            <Link element={RouterLink} to="/help-center">
              Help Center
            </Link>
            .
          </P>
        </div>
      </div>
    </Page>
  );
}
