import { Button, CopyOnClick, Flex, ProgressIndicator } from '@dnb/eufemia';
import { copy as CopyIcon, download as DownloadIcon } from '@dnb/eufemia/icons';
import type { AppWithEntitlementsDto } from '@portals/shared/portal/AppDto';
import Markdown from '@portals/shared-frontend/components/Markdown';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import ModalPage from '@/components/ModalPage';
import { toJsonMarkdown } from '@/utils/markdownUtils';

function downloadFile(fileName: string, fileContent: string) {
  const blob = new Blob([fileContent], { type: 'text/json' });
  const link = document.createElement('a');
  link.download = fileName;
  link.href = URL.createObjectURL(blob);
  document.body.append(link);
  link.click();
}

export default function AsyncApiModal() {
  const { id: appId } = useParams();

  const { data: app, isValidating: appLoading } =
    useSWR<AppWithEntitlementsDto>(appId && `/apps/${appId}`);
  const { data: asyncApi, isValidating: asyncApiLoading } = useSWR<object>(
    appId && `/v2/apps/${appId}/async-api`,
  );

  const asyncApiJson = useMemo(() => {
    if (!asyncApi) return;
    return JSON.stringify(asyncApi, null, '\t');
  }, [asyncApi]);

  return (
    <ModalPage skeleton={appLoading} title={`AsyncAPI for ${app?.name}`}>
      {asyncApiLoading || !asyncApiJson || !app ? (
        <ProgressIndicator />
      ) : (
        <>
          <Flex.Horizontal>
            <CopyOnClick copyContent={asyncApiJson}>
              <Button icon={<CopyIcon />}>Copy</Button>
            </CopyOnClick>
            <Button
              icon={<DownloadIcon />}
              onClick={() =>
                downloadFile(`${app.name}-asyncapi.json`, asyncApiJson)
              }
            >
              Download
            </Button>
          </Flex.Horizontal>
          <Markdown>{toJsonMarkdown(asyncApiJson)}</Markdown>
        </>
      )}
    </ModalPage>
  );
}
