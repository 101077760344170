import { Card, P, Table, Td, Tr } from '@dnb/eufemia';
import { ServiceNowDigitalIntegrationRequestDto } from '@portals/shared/portal/SupportTicket';
import type { JSX } from 'react';
import useSWR from 'swr';

type DigitalIntegrationRequestDetailsProps = {
  requestId: string;
};
export default function DigitalIntegrationRequestDetails({
  requestId,
}: DigitalIntegrationRequestDetailsProps): JSX.Element {
  const { data: request, isValidating } =
    useSWR<ServiceNowDigitalIntegrationRequestDto>(
      `/service-now/digital-integration-requests/${requestId}`,
    );
  return (
    <Card
      filled
      innerSpace={0}
      responsive={false}
      skeleton={isValidating}
      title={
        <>
          <b>Ticket Id: </b>
          {requestId}
        </>
      }
    >
      <Table.ScrollView>
        <Table border outline size="medium">
          <tbody>
            {request?.approvals.map((approval) => (
              <Tr key={approval.userName}>
                <Td>
                  <P>
                    {approval.name} ({approval.email})
                  </P>
                  Status: {approval.state}
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </Table.ScrollView>
    </Card>
  );
}
