import { Dialog, Link, P } from '@dnb/eufemia';
import { useAsync } from '@portals/shared-frontend/hooks';
import type { JSX } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { acknowledgeOrganization } from '@/api/user';
import { useUser } from '@/hooks/useUser';

export default function OrganizationNotificationAlert(): JSX.Element {
  const { user, reload } = useUser();
  const onAcknowledgeOrganization = useAsync(async () => {
    await acknowledgeOrganization();
    reload();
  }, [reload]);

  return (
    <Dialog
      confirmText="Continue"
      hideDecline
      icon="information_medium"
      onConfirm={onAcknowledgeOrganization.execute}
      openState
      title={`You have been added to the ${user?.organizationName} organization`}
      top="medium"
      triggerAttributes={{ hidden: true }}
      variant="confirmation"
    >
      <P>
        This might mean you get access to more APIs and documentation for those
        APIs. If you have any questions regarding this or in general, please{' '}
        <Link element={RouterLink} to="/help-center">
          get in touch
        </Link>{' '}
        and we will get back to you as soon as possible.
      </P>
    </Dialog>
  );
}
