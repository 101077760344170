import { GlobalError, Space } from '@dnb/eufemia';
import type { OpenAPIV3 } from 'openapi-types';
import type { JSX } from 'react';
import useSWR from 'swr';

import LoadingModal from '@/components/LoadingModal';
import useSWRExpect404 from '@/hooks/useSWRExpect404';
import ReferenceView from '@/pages/api-documentation/sections/reference/components/ReferenceView';

import type { APIType } from '../../api-documentation/constants/types';

interface ReferenceFromCommonRepoProps {
  apiLink: string;
}

export default function ReferenceFromCommonRepo({
  apiLink,
}: ReferenceFromCommonRepoProps): JSX.Element {
  const swrExpect404 = useSWRExpect404();
  const { data, isValidating } = useSWR<APIType | null>(
    `/common-repo/reference/${encodeURIComponent(apiLink)}`,
    swrExpect404,
  );

  if (isValidating) {
    return <LoadingModal />;
  }
  if (!data) {
    return (
      <Space bottom={10}>
        <GlobalError title="No reference present" />
      </Space>
    );
  }

  return (
    <ReferenceView reference={data?.reference ?? ({} as OpenAPIV3.Document)} />
  );
}
