import {
  Button,
  Dialog,
  FormRow,
  FormSet,
  GlobalError,
  Input,
  P,
} from '@dnb/eufemia';
import { email_medium as EmailMediumIcon } from '@dnb/eufemia/icons';
import type { ApiDto } from '@portals/shared/portal/ApiDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { type JSX, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { z } from 'zod';

import { createSubscriptions } from '@/api/subscriptions';
import Container from '@/components/Container';
import ModalPage from '@/components/ModalPage';
import { useUser } from '@/hooks/useUser';

const releasesSubscriptionsFormSchema = z.object({
  email: z.string().email(),
});

export default function ReleasesSubscriptions(): JSX.Element {
  const { apiSlug } = useParams();
  const { data: apis, isValidating: apisLoading } = useSWR<ApiDto[]>('/api');
  const navigate = useNavigate();
  const { user } = useUser();

  const {
    register,
    handleSubmit,
    submitting,
    controller: { setValue, getValue, formError },
  } = useEufemiaForm(releasesSubscriptionsFormSchema, {});

  const hasLoaded = !apisLoading && !!apis;
  const api = apis?.find((api) => api.slug === apiSlug);

  useEffect(() => {
    if (user?.email) {
      setValue('email', user.email);
    }
  }, [setValue, user?.email]);

  const onSubmit = handleSubmit(
    useCallback(
      async (data) => {
        if (api) {
          await createSubscriptions({
            email: data.email,
            releases: [api.id],
          });
          setOpen(true);
        }
      },
      [api],
    ),
  );

  const [open, setOpen] = useState(false);

  if (hasLoaded && !api) {
    return <GlobalError status="404" />;
  }

  const formEmailValue = getValue('email') as string | undefined;

  return (
    <ModalPage
      onClose={() => navigate(`/documentation/${apiSlug}/@default/@latest`)}
      skeleton={!hasLoaded}
      subtitle="Subscriptions"
      title="Releases"
    >
      <Container size="small">
        <FormSet on_submit={onSubmit} vertical>
          <FormRow>
            <P>
              Please fill in your email below to receive notifications when{' '}
              <strong>{api && api.name} API</strong> releases a new version.
            </P>
            <Input
              icon={EmailMediumIcon}
              placeholder="Email address"
              size="large"
              stretch
              top="medium"
              {...register.input('email')}
            />
          </FormRow>
          <FormRow top="small">
            <Button
              disabled={submitting}
              size="large"
              status={formError}
              type="submit"
            >
              Confirm
            </Button>
          </FormRow>
        </FormSet>
      </Container>
      <Dialog
        confirmText="Got it!"
        description={
          <P>
            We&apos;ve sent a confirmation email to {formEmailValue}. If you
            didn&apos;t receive it, check your spam filter.
          </P>
        }
        hideDecline
        onConfirm={() => navigate(`/documentation/${apiSlug}/@default/@latest`)}
        openState={open}
        title="Success! An email is on its way"
        variant="confirmation"
      />
    </ModalPage>
  );
}
