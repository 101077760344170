import { Space } from '@dnb/eufemia';
import type { ApiSubscribersDto } from '@portals/shared/portal/SubscriptionDto';
import useSWR from 'swr';

import { SubscribersContainer } from '@/pages/profile/apps/application/components/SubscribersContainer';

interface Props {
  apiId: string;
}

export default function SubscribersSection({ apiId }: Props) {
  const { data: subs } = useSWR<ApiSubscribersDto>(
    apiId && `/subscriptions/apis/${apiId}`,
  );

  return (
    <>
      <SubscribersContainer
        subscribers={subs?.releaseSubscribers ?? []}
        title={`Subscribers to new releases: ${subs?.releaseSubscribers?.length}`}
      />
      <Space top="small" />
      <SubscribersContainer
        subscribers={subs?.statusSubscribers ?? []}
        title={`Subscribers to status updates: ${subs?.statusSubscribers?.length}`}
      />
    </>
  );
}
