import { Card, Checkbox, H2, H4, P, Space, Table, Td, Tr } from '@dnb/eufemia';

import Divider from '@/components/Divider';

import style from './index.module.css';

type ScopesProps = {
  id: string;
  name: string;
  description: string;
  selected: boolean;
};
type RequestScopesProps = {
  scopes: ScopesProps[];
  apiName: string;
  apiDescription: string | null;
  apiSubtitle?: string;
  scopeSubtitle?: string;
  onChange: (scopeId: string, checked: boolean) => void;
};

export default function RequestScopes({
  scopes,
  apiName,
  apiDescription,
  apiSubtitle,
  scopeSubtitle,
  onChange,
}: RequestScopesProps) {
  return (
    <>
      <H2>Select integration service scopes</H2>
      <P bottom="small" top="small">
        {apiSubtitle ??
          'Select the integration service scopes you want to request access for'}
      </P>
      <Card>
        <H4>{apiName}</H4>
        <P top="small">{apiDescription}</P>
        <Space bottom="small" top="small">
          <Divider />
        </Space>
        <P bold>
          {scopeSubtitle ?? 'Select the scopes you wish to request access for'}
        </P>
        <Table className={style['Table']}>
          <tbody>
            {scopes?.map((scope) => {
              return (
                <Tr key={scope.id}>
                  <Td>
                    <Checkbox
                      checked={scope.selected}
                      label={scope.name}
                      on_change={({ checked }) => onChange(scope.id, checked)}
                      value={scope.id}
                    />
                  </Td>
                  <Td>{scope.description}</Td>
                </Tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    </>
  );
}
