import { Button, Div, H2, P, Space } from '@dnb/eufemia';
import type { JSX } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

import Card from '@/components/Card';
import Container from '@/components/Container';
import ModalPage from '@/components/ModalPage';
import useReturnTo from '@/hooks/useReturnTo';

import style from './index.module.css';

import clapIllustrationUrl from '@/illustrations/clap.svg?url';

type SuccessModalProps = {
  title?: string;
  subTitle?: string;
  to?: string;
};

export default function SuccessModal({
  title,
  subTitle,
  to,
}: SuccessModalProps): JSX.Element {
  const [searchParams] = useSearchParams();
  const [returnTo] = useReturnTo();

  const _title = searchParams.get('title');
  const _subtitle = searchParams.get('subtitle');
  const _to = to || (returnTo ?? '/');

  return (
    <ModalPage hideCloseButton>
      <Container centered size="small">
        <Card className={style['Card']}>
          <H2 bottom={0} className={style['success-text']}>
            Yay!
          </H2>
          <H2 bottom={0}>{title ?? _title ?? 'Success!'}</H2>
          <Space bottom="medium">
            <img src={clapIllustrationUrl} />
          </Space>
          <P bottom="medium" size="small">
            {subTitle ?? _subtitle ?? 'Your request has been sent!'}
          </P>
          <Div>
            <Button
              element={RouterLink}
              size="large"
              text="Continue"
              to={_to}
              variant="secondary"
            />
          </Div>
        </Card>
      </Container>
    </ModalPage>
  );
}
