import { Anchor, Card, Flex, Tag } from '@dnb/eufemia';
import { Link } from 'react-router-dom';

import ApiFavouriteButton from '@/components/ApiFavouriteButton';
import { EventResponse } from '@/openapi/types';

import style from './index.module.css';

interface EventCardProps {
  event: EventResponse;
  onFavoriteChange: (eventId: string, isFavorite: boolean) => void;
}

export default function EventCard({ event, onFavoriteChange }: EventCardProps) {
  return (
    <Card className={style['Card']} stack>
      <Flex.Horizontal justify="space-between">
        <Anchor
          element={Link}
          to={`/events/${event.slug}/@default/@latest/schemas`}
        >
          {event.name}
        </Anchor>
        <ApiFavouriteButton
          apiId={event.id}
          isFavorite={event.isFavorite}
          onChange={onFavoriteChange}
        />
      </Flex.Horizontal>
      <Flex.Item>
        <Tag text={event.classification} />
      </Flex.Item>
    </Card>
  );
}
