import {
  Button,
  Checkbox,
  Dialog,
  Div,
  Drawer,
  FormRow,
  H2,
  P,
  Section,
  Space,
  Table,
  Td,
  Th,
  Tr,
} from '@dnb/eufemia';
import { trash as TrashIcon } from '@dnb/eufemia/icons';
import {
  hourglass as HourglassIcon,
  stop as StopIcon,
} from '@dnb/eufemia/icons';
import styled from '@emotion/styled';
import {
  Decision,
  type EntitlementRequestWithScopeDto,
} from '@portals/shared/portal/AppApiEntitlementDto';
import useAsync from '@portals/shared-frontend/hooks/useAsync';
import { type JSX, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';

import { acknowledgeDenial, cancelPendingRequest } from '@/api/app';
import Card from '@/components/Card';
import LoadingModal from '@/components/LoadingModal';

import ScopeCard from '../ScopeCard';
import RequestTimeline from './components/RequestTimeline';

import style from './index.module.css';

type EntitlementRequestProps = {
  entitlementRequests: EntitlementRequestWithScopeDto[];
  isAdmin: boolean;
};

function convertDecisionToText(status: Decision) {
  const Status = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `;
  switch (status) {
    case Decision.PRESUBMIT: {
      return (
        <Status>
          <HourglassIcon />
          <P left="x-small">Processing</P>
        </Status>
      );
    }
    case Decision.PENDING: {
      return (
        <Status>
          <HourglassIcon />
          <P left="x-small">Waiting for review</P>
        </Status>
      );
    }
    case Decision.DENIED: {
      return (
        <Status>
          <StopIcon />
          <P left="x-small">Your access is denied</P>
        </Status>
      );
    }
    case Decision.LEVEL1_APPROVED: {
      return (
        <Status>
          <StopIcon />
          <P left="x-small">Waiting for final approval</P>
        </Status>
      );
    }
    default: {
      return 'Unknown status';
    }
  }
}

export default function EntitlementRequest({
  entitlementRequests,
  isAdmin,
}: EntitlementRequestProps): JSX.Element {
  const [userAck, setUserAck] = useState(false);
  const { id: appId } = useParams();
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const onCancelRequest = useAsync(
    async (apiId: string, entitlementRequestId: string) => {
      if (appId) {
        await cancelPendingRequest(appId, apiId, entitlementRequestId);
        mutate(`/apps/${appId}`);
        setShowCancelDialog(false);
      }
    },
    [appId],
  );

  const onAcknowledgeDenial = useAsync(
    async (apiId: string, entitlementRequestId: string) => {
      if (appId) {
        await acknowledgeDenial(appId, apiId, entitlementRequestId);
        mutate(`/apps/${appId}`);
        setShowCancelDialog(false);
      }
    },
    [appId],
  );

  return (
    <Card>
      {(onCancelRequest.waiting || onAcknowledgeDenial.waiting) && (
        <LoadingModal />
      )}
      <Table className={style['Table']}>
        <thead>
          <Tr>
            <Th>API</Th>
            <Th>Status</Th>
            <Th>Actions</Th>
          </Tr>
        </thead>
        <tbody>
          {entitlementRequests.map((request) => (
            <Tr key={request.id}>
              <Td>{request.apiName}</Td>
              <Td>{convertDecisionToText(request.decision)}</Td>
              <Td>
                <Drawer
                  key={request.id}
                  title="Your request"
                  triggerAttributes={{ text: 'View' }}
                >
                  <Drawer.Navigation />
                  <Drawer.Header>
                    <P bottom>
                      The list below displays the scopes you have requested
                      access for.
                    </P>
                  </Drawer.Header>
                  <Drawer.Body>
                    <Section spacing="x-large" style_type="transparent">
                      <H2>{request.apiName}</H2>
                      <RequestTimeline request={request} />
                      {request.scopes.length === 0 && (
                        <ScopeCard
                          environments={['Test', 'Live']}
                          reviewStatus={request.decision}
                          scopeEntitlement={{
                            scopeName: 'Full access',
                            scopeDescription: 'Requst for full access',
                            liveMode: true,
                            scopeId: 'all',
                          }}
                          showAccessSection={false}
                        />
                      )}
                      {request.scopes.map((scope) => (
                        <Space bottom="small" key={scope.name}>
                          <ScopeCard
                            environments={['Test', 'Live']}
                            reviewStatus={request.decision}
                            scopeEntitlement={{
                              scopeDescription: scope.description,
                              scopeName: scope.name,
                              liveMode: true,
                              scopeId: scope.id,
                            }}
                            showAccessSection={false}
                          />
                        </Space>
                      ))}
                      {isAdmin &&
                        (request.decision == Decision.DENIED ? (
                          <>
                            <Div top="medium">
                              <Checkbox
                                label="I have read and understood why my request was rejected. "
                                onChange={({ checked }) => setUserAck(checked)}
                              />
                            </Div>

                            <Button
                              disabled={!userAck}
                              on_click={() =>
                                onAcknowledgeDenial.execute(
                                  request.apiId,
                                  request.id,
                                )
                              }
                              text="Confirm and close"
                              top="small"
                            />
                          </>
                        ) : (
                          <FormRow direction="horizontal" top="small">
                            <Button
                              icon={TrashIcon}
                              icon_position="left"
                              on_click={() => setShowCancelDialog(true)}
                              text="Cancel Request"
                              variant="signal"
                            />
                            <Dialog
                              confirmText="Confirm"
                              description={
                                <>
                                  Canceling a request completely deletes it. You
                                  can make a new request whenever you like.
                                </>
                              }
                              onConfirm={() =>
                                onCancelRequest.execute(
                                  request.apiId,
                                  request.id,
                                )
                              }
                              onDecline={() => setShowCancelDialog(false)}
                              openState={showCancelDialog}
                              title="Are you sure you want to cancel the request?"
                              triggerAttributes={{
                                hidden: true,
                              }}
                              variant="confirmation"
                            />
                          </FormRow>
                        ))}
                    </Section>
                  </Drawer.Body>
                </Drawer>
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
}
