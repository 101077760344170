import { isStringArray } from '@portals/shared/common/utils';
import { hasOwnProperty } from '@portals/shared-frontend/utils';
import { ReactNode, useCallback, useEffect, useState } from 'react';

import { isSignedInWithAzure, msalInstance } from '@/auth/azure';
import { isLoggedInWithCognito } from '@/auth/cognito';
import { CELEBRUS_LOGIN_STATE_KEY } from '@/constants';
import AuthContext from '@/hooks/AuthContext';

export default function AuthContextProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const [isLoggedIn, setIsLoggedIn] = useState<'azure' | 'cognito'>();
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState<string[]>([]);

  const checkIsLoggedIn = useCallback(async () => {
    setLoading(true);
    const [_isLoggedInWithAzure, _isLoggedInWithCognito] = await Promise.all([
      isSignedInWithAzure(),
      isLoggedInWithCognito(),
    ]);
    if (_isLoggedInWithAzure) {
      setIsLoggedIn('azure');
    } else if (_isLoggedInWithCognito) {
      setIsLoggedIn('cognito');
    } else {
      setIsLoggedIn(undefined);
    }
    setLoading(false);
  }, [setLoading, setIsLoggedIn]);

  useEffect(() => {
    checkIsLoggedIn();
  }, [checkIsLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) {
      sessionStorage.removeItem(CELEBRUS_LOGIN_STATE_KEY);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const account = msalInstance.getAllAccounts()[0];
    if (account) {
      const idTokenClaims = account.idTokenClaims;
      if (idTokenClaims && hasOwnProperty(idTokenClaims, 'roles')) {
        const idTokenRoles = idTokenClaims['roles'];
        if (isStringArray(idTokenRoles)) {
          setRoles(idTokenRoles);
        }
      }
    }
  }, [isLoggedIn]);

  return (
    <AuthContext.Provider
      value={{
        isSignedIn: !!isLoggedIn,
        isLoading: loading,
        reload: checkIsLoggedIn,
        roles: roles,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
