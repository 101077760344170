import { Div, Flex, H1, P } from '@dnb/eufemia';
import { useMedia } from '@dnb/eufemia/shared';
import classNames from 'classnames';
import { type JSX, PropsWithChildren, ReactNode } from 'react';

import style from './index.module.css';

import * as Styled from './index.styled';

type HeroHeaderProps = PropsWithChildren<{
  image: string;
  altText: string;
  heroText: string;
  heroSubtitle?: string;
  heroLeftComponent?: ReactNode;
}>;

const HeroHeader = ({
  image,
  heroText,
  heroSubtitle,
  children,
  heroLeftComponent,
}: HeroHeaderProps): JSX.Element => {
  const { isSmall } = useMedia();

  const noChildrenOrSmallScreen = children || isSmall;

  return (
    <Styled.HeroHeaderWrapper
      backgroundImage={image}
      id="HeroHeader"
      isSmall={isSmall}
    >
      <Div
        className={
          noChildrenOrSmallScreen
            ? style['HeroContent']
            : classNames(style['HeroContentNoChildren'], style['HeroContent'])
        }
      >
        <div className={style['heroTextContainer']}>
          <Flex.Horizontal
            align="center"
            gap={false}
            justify={noChildrenOrSmallScreen ? 'space-evenly' : 'space-between'}
          >
            <Div className={style['HeroText']}>
              {heroLeftComponent ?? (
                <>
                  <H1 size="x-large">{heroText}</H1>
                  {heroSubtitle && <P modifier="medium">{heroSubtitle}</P>}
                </>
              )}
            </Div>
            {children && <div>{children}</div>}
          </Flex.Horizontal>
        </div>
      </Div>
    </Styled.HeroHeaderWrapper>
  );
};

export default HeroHeader;
