import { Anchor, Badge, Flex, P, Tag, Tooltip } from '@dnb/eufemia';
import { WarnIcon } from '@dnb/eufemia/components/FormStatus';
import {
  exclamation_circled as NotSharedIcon,
  office_buildings as OrgIcon,
  people_1 as TeamIcon,
} from '@dnb/eufemia/icons';
import { ProviderApiDto } from '@portals/shared/portal/ProviderApiDto';
import { Link } from 'react-router-dom';

import ResourceCard from '@/pages/profile/apps/components/ResourceCard';
import { convertCamelCaseToTitleCase } from '@/utils';

const missingServiceNowIcon = (
  <Tooltip
    targetElement={
      // Tag must be wrapped in div, otherwise tooltip won't appear
      <div>
        <WarnIcon title="" />
      </div>
    }
  >
    <P>
      ServiceNow information is required before consumers can request access
    </P>
  </Tooltip>
);

function OrganizationTag({ name }: { name: string }) {
  // Tag must be wrapped in div, otherwise tooltip won't appear
  const tag = (
    <div>
      <Tag icon={<OrgIcon />} text={name} />
    </div>
  );
  return <Tooltip targetElement={tag}>Organization</Tooltip>;
}

function TeamTag({ name }: { name: string }) {
  // Tag must be wrapped in div, otherwise tooltip won't appear
  const tag = (
    <div>
      <Tag icon={<TeamIcon />} text={name} />
    </div>
  );
  return <Tooltip targetElement={tag}>Team</Tooltip>;
}

const notSharedTag = (
  <Tooltip
    targetElement={
      // Tag must be wrapped in div, otherwise tooltip won't appear
      <div>
        <Tag icon={<NotSharedIcon />} text="None" />
      </div>
    }
  >
    Visibility has not been configured
  </Tooltip>
);

interface Props {
  api: ProviderApiDto;
}

export default function ProviderApiCard({ api }: Props) {
  return (
    <ResourceCard
      api={api}
      headerContent={
        <Flex.Horizontal>
          <Anchor element={Link} target="_blank" to={`api/${api.id}`}>
            {api.name}
          </Anchor>
          <Badge content={convertCamelCaseToTitleCase(api.stage)} />
          {!api.enableServiceNowRequest &&
            api.stage === 'launched' &&
            missingServiceNowIcon}
        </Flex.Horizontal>
      }
      key={api.id}
    >
      <Flex.Stack>
        <P size="small">
          <b>Classification:</b> {api.classification}
        </P>
        <Flex.Horizontal align="center" gap="x-small">
          <P size="small">
            <b>Visible to:</b>
          </P>
          {api.organizations.length > 0 || api.teams.length > 0 ? (
            <Flex.Horizontal gap="x-small">
              {api.organizations.map((orgName) => (
                <OrganizationTag key={orgName} name={orgName} />
              ))}
              {api.teams.map((teamName) => (
                <TeamTag key={teamName} name={teamName} />
              ))}
            </Flex.Horizontal>
          ) : (
            notSharedTag
          )}
        </Flex.Horizontal>
        {api.description && (
          <P size="small">
            <b>Description:</b> {api.description}
          </P>
        )}
      </Flex.Stack>
    </ResourceCard>
  );
}
